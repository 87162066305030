.hero-slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    .slide-info {
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        @media (max-width: $mobile) {
            width: 100%;
            padding: 0 10px;
        }
        .super-hero {
            display: block;
            text-transform: capitalize;
            font-size: clamp(3rem, 4.167vw, 8rem);
            text-align: center;
            margin: 0 0 40px;
            @media (max-width: $tablet) {
                margin: 0 0 30px;
            }
            @media (max-width: $mobile) {
                margin: 0 auto 30px;
                max-width: 350px;
            }
            .smaller-text {
                font-size: clamp(2.437rem, 3.385vw, 6.5rem);
                text-transform: lowercase;
            }
        }
        .hero-title {
            margin: 25px 0 40px;
        }
    }
    .swiper-slide img {
        margin: 0 auto;
    }
    .hero-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;
        max-width: 22px;
        @media (max-width: $mobile) {
            max-width: 15px;
        }
        &.hero-button-prev {
            left: 2%;
        }
        &.hero-button-next {
            right: 2%;
        }
    }
}

@font-face {
    font-family: 'Legan';
    src: url('../../../fonts/Legan.eot');
    src: url('../../../fonts/Legan.eot?#iefix') format('embedded-opentype'), url('../../../fonts/Legan.woff2') format('woff2'),
        url('../../../fonts/Legan.woff') format('woff'), url('../../../fonts/Legan.ttf') format('truetype'),
        url('../../../fonts/Legan.svg#Legan') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-XLt.eot');
    src: url('../../../fonts/FuturaNowText-XLt.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-XLt.woff2') format('woff2'), url('../../../fonts/FuturaNowText-XLt.woff') format('woff'),
        url('../../../fonts/FuturaNowText-XLt.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-XLt.svg#FuturaNowText-XLt') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-Lt.eot');
    src: url('../../../fonts/FuturaNowText-Lt.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-Lt.woff2') format('woff2'), url('../../../fonts/FuturaNowText-Lt.woff') format('woff'),
        url('../../../fonts/FuturaNowText-Lt.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-Lt.svg#FuturaNowText-Lt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-LtIt.eot');
    src: url('../../../fonts/FuturaNowText-LtIt.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-LtIt.woff2') format('woff2'), url('../../../fonts/FuturaNowText-LtIt.woff') format('woff'),
        url('../../../fonts/FuturaNowText-LtIt.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-LtIt.svg#FuturaNowText-LtIt') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-Rg.eot');
    src: url('../../../fonts/FuturaNowText-Rg.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-Rg.woff2') format('woff2'), url('../../../fonts/FuturaNowText-Rg.woff') format('woff'),
        url('../../../fonts/FuturaNowText-Rg.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-Rg.svg#FuturaNowText-Rg') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-It.eot');
    src: url('../../../fonts/FuturaNowText-It.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-It.woff2') format('woff2'), url('../../../fonts/FuturaNowText-It.woff') format('woff'),
        url('../../../fonts/FuturaNowText-It.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-It.svg#FuturaNowText-It') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-Md.eot');
    src: url('../../../fonts/FuturaNowText-Md.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-Md.woff2') format('woff2'), url('../../../fonts/FuturaNowText-Md.woff') format('woff'),
        url('../../../fonts/FuturaNowText-Md.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-Md.svg#FuturaNowText-Md') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-MdIt.eot');
    src: url('../../../fonts/FuturaNowText-MdIt.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-MdIt.woff2') format('woff2'), url('../../../fonts/FuturaNowText-MdIt.woff') format('woff'),
        url('../../../fonts/FuturaNowText-MdIt.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-MdIt.svg#FuturaNowText-MdIt') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-XBd.eot');
    src: url('../../../fonts/FuturaNowText-XBd.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-XBd.woff2') format('woff2'), url('../../../fonts/FuturaNowText-XBd.woff') format('woff'),
        url('../../../fonts/FuturaNowText-XBd.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-XBd.svg#FuturaNowText-XBd') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-XBd.eot');
    src: url('../../../fonts/FuturaNowText-XBd.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-XBd.woff2') format('woff2'), url('../../../fonts/FuturaNowText-XBd.woff') format('woff'),
        url('../../../fonts/FuturaNowText-XBd.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-XBd.svg#FuturaNowText-XBd') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Now';
    src: url('../../../fonts/FuturaNowText-XBlk.eot');
    src: url('../../../fonts/FuturaNowText-XBlk.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/FuturaNowText-XBlk.woff2') format('woff2'), url('../../../fonts/FuturaNowText-XBlk.woff') format('woff'),
        url('../../../fonts/FuturaNowText-XBlk.ttf') format('truetype'),
        url('../../../fonts/FuturaNowText-XBlk.svg#FuturaNowText-XBlk') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/*========================================================*/
/* Homepage */
/*========================================================*/
.home {
    footer {
        margin-top: 0;
    }
}
/*banner-slider*/
.banner-slider {
    max-width: 1770px;
    margin: 0 auto;
    padding: clamp(40px, 6.510vw, 125px) var(--side-padding);
    @media (max-width: $mobile) {
        padding-bottom: 10px;
    }
    .banner-slider-item {
        justify-content: space-between;

        @media (max-width: $tablet-sm) {
            flex-direction: column-reverse;
        }
        .banner-info {
            flex: 1 0 50%;
            max-width: 50%;
            padding-right: 15px;
            @media (max-width: $tablet-sm) {
                justify-content: center;
                padding-right: 0;
                flex-basis: 100%;
                max-width: 100%;
            }
            .info-block {
                width: 100%;
                max-width: 670px;
                padding-bottom: 15px;
                p {
                    max-width: 540px;
                }
                .more-button {
                    margin-top: 10px;
                }
            }
            .products-banner-container {
                max-width: 670px;
                margin: 0;
                @media (max-width: $tablet) {
                    max-width: 100%;
                    margin: 0 auto;
                }
                .product-thumb {
                    .image {
                        background-color: $light-beige;
                        .product-badges {
                            .badge {
                                &.new {
                                    @media (max-width: $tablet) {
                                        background-color: #ececec;
                                        border: 1px solid #ececec;
                                    }
                                }
                            }

                        }
                    }
                    @media (max-width: $laptop-sm) {
                        max-width: 350px;
                        margin: 0 auto;
                    }
                    @media (max-width: $tablet) {
                        max-width: 275px;
                        margin: 0 auto;
                    }
                    @media (max-width: $tablet-sm) {
                        max-width: 350px;
                        margin: 0 auto;
                    }
                    @media (max-width: $mobile-sm) {
                        max-width: unset;
                        .image {
                            img {
                                max-width: 290px;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
        .right {
            flex: 1 0 50%;
            max-width: 50%;
            padding-left: 15px;
            @media (max-width: $tablet-sm) {
                flex-basis: 100%;
                padding-left: 0;
                max-width: 100%;
                padding-right: 0;
            }
            .second-image-wrapper {
                display: block;
                height: 100%;
                width: 100%;
                .image {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    background-blend-mode: darken;
                    background-color: $light-beige;
                    -webkit-transition: opacity 0.6s ease;
                    -moz-transition: opacity 0.6s ease;
                    transition: opacity 0.6s ease;
                    @media (max-width: $tablet-sm) {
                        height: 75vh;
                    }
                    @media (max-width: $mobile-sm) {
                        height: 65vh;
                    }
                }
            }
        }

        &.reverse {
            flex-direction: row-reverse;
            @media (max-width: $tablet-sm) {
                flex-direction: column-reverse;
            }
            .banner-info {
                padding-left: 15px;
                padding-right: 0;
                @media (max-width: $tablet-sm) {
                    padding-left: 0;
                }
                .info-block {
                    margin: 0 0 0 auto;
                }
                .products-banner-container {
                    margin: 0 0 0 auto;
                }
            }
            .right {
                padding-left: 0;
                padding-right: 15px;
                @media (max-width: $tablet-sm) {
                    padding-right: 0;
                }
            }
        }
    }
    .info-block {
        &.laptop-view {
            text-align: center;
            max-width: 600px;
            margin: 0 auto 60px;
            @media (max-width: $mobile) {
                margin: 0 auto 45px;
                .more-button {
                    margin-top: 15px;
                }
            }
        }
    }
    &.child-room {
        @media (max-width: $mobile) {
           padding-bottom: 60px;
        }
        .child-products {
            @media (max-width: $tablet-sm) {
                padding-top: 20px;
            }
            .single-product-banner {
                @media (max-width: $tablet) {
                    background-color: $beige;
                }
                @media (max-width: $tablet-sm) {
                    .product-thumb {
                        background-color: $beige;
                    }
                }
                &:first-child {
                    margin-right: 1.5px;
                }
                &:nth-child(2){
                    margin-left: 1.5px;
                }
            }
        }
    }
}

/*kitchen banner*/
.kitchen-banner-container {
    padding: clamp(25px, 6.510vw, 125px) var(--side-padding);
    .kitchen-banner-wrapper {
        position: relative;
        @media (max-width: $mobile) {
            img {
                object-fit: cover;
                height: 390px;
            }
        }
        .kitchen-info-box {
            width: 100%;
            max-width: 620px;
            position: absolute;
            bottom: 60px;
            left: 60px;
            background-color: $oil;
            color: $white;
            padding: 55px 25px 50px 45px;
            @media (max-width: $tablet) {
                max-width: 270px;
                padding: 15px;
                text-align: center;
                bottom: 20px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
            }
            .header-1 {
                color: $white;
            }
            .more-button {
                margin-top: 40px;
                &::after {
                    background: url('../../../icons/arrow-next-white.svg');
                }
            }
            @media (max-width: $mobile) {
                .more-button {
                    margin-top: 10px;
                }
            }
        }
    }
    .kitchen-grid {
        padding-top: 50px;
        @media (max-width: $tablet) {
            .product-column {
                .product-thumb {
                    background-color: $beige;
                }
            }
        }
        @media (max-width: $mobile) {
            padding-top: 20px;
        }
    }
}

/*personal-care banner*/
.personal-care-banner-container, .pet-banner-container, .cleaning-banner-container {
    padding: clamp(25px, 6.510vw, 125px) var(--side-padding);
    .personal-care-banner-wrapper, .pet-banner-wrapper, .cleaning-banner-wrapper {
        position: relative;
        @media (max-width: $mobile) {
            img {
                object-fit: cover;
                height: 390px;
            }
        }
        .personal-care-info-box, .pet-info-box, .cleaning-info-box {
            width: 100%;
            max-width: 620px;
            position: absolute;
            bottom: 60px;
            left: 60px;
            background-color: $oil;
            color: $white;
            padding: 55px 25px 50px 45px;
            @media (max-width: $tablet) {
                max-width: 270px;
                padding: 15px;
                text-align: center;
                bottom: 20px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
            }
            .header-1 {
                color: $white;
            }
            .more-button {
                margin-top: 40px;
                &::after {
                    background: url('../../../icons/arrow-next-white.svg');
                }
            }
            @media (max-width: $mobile) {
                .more-button {
                    margin-top: 10px;
                }
            }
        }
    }
    .personal-care-grid, .pet-grid, .cleaning-grid {
        padding-top: 50px;
        @media (max-width: $tablet) {
            .product-column {
                .product-thumb {
                    background-color: $beige;
                }
            }
        }
        @media (max-width: $mobile) {
            padding-top: 20px;
        }
    }
}

.home-posts {
    max-width: 1760px;
    margin: 0 auto;
}

/*nature banner*/
.nature-banner-container {
    padding: 0;
    .nature-banner-wrapper {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5.729vw 8.854vw 7.292vw 5.208vw;
        @media (max-width: $tablet) {
            padding: 5.729vw var(--side-padding);
        }
        @media (max-width: $tablet-sm) {
            padding-top: 75px;
            padding-bottom: 40px;
            background-position: top;
        }
        .header-1 {
            color: $white;
            max-width: 380px;
            margin-bottom: 60px;
            &.tablet-view {
                text-align: center;
                margin: 0 auto 40px;
            }
        }
        .nature-info-box {
            width: 100%;
            max-width: 620px;
            background-color: $oil;
            color: $white;
            margin: 0 0 0 auto;
            padding: 6.510vw 30px 7.031vw 65px;
            @media (max-width: $laptop-m) {
                max-width: 520px;
                padding-left: 55px;
                padding-right: 55px;
            }
            @media (max-width: $laptop-sm) {
                padding-top: 55px;
                padding-bottom: 65px;
            }
            @media (max-width: $tablet) {
                margin: 0 auto;
                padding: 55px 25px;
            }
            .header-1 {
                color: $white;
                max-width: 380px;
                margin-bottom: 60px;
                @media (max-width: $laptop-sm) {
                    margin-bottom: 30px;
                }
            }
            p {
                max-width: 400px;
                @media (max-width: $tablet) {
                    max-width: unset;
                }
            }
            .more-button {
                margin-top: 4.167vw;
                @media (max-width: $laptop-sm) {
                    margin-top: 20px;
                }
                &::after {
                    background: url('../../../icons/arrow-next-white.svg');
                }
            }
        }
    }
}

/*homepage blog*/
.homepage-blog {
    padding: clamp(50px, 6.510vw, 125px) var(--side-padding) ;
    background-color: $beige;
    .section-wrap {
        &:first-child {
            text-align: center;
            padding-bottom: 70px;
            @media (max-width: $tablet) {
                padding-bottom: 50px;
                p {
                    max-width: 250px;
                    margin: 0 auto;
                }
            }
        }
    }
    .home-posts {
        .post-link {
            .date {
                display: none;
            }
            @media (max-width: $tablet) {
                margin-bottom: 0;
            }
        }
    }

    .btn-default {
        width: 100%;
        margin: 0 auto;
        display: block;
    }
}


.homepage-banner-pagination,
.home-pagination,
.kitchen-banner-pagination,
.personal-care-banner-pagination,
.pet-banner-pagination,
.cleaning-banner-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px 0 15px;
    .swiper-pagination-bullet {
        width: 16px;
        height: 16px;
        margin: 0 7px;
        border: 1px solid $dark-oil;
        background-color: transparent;
        &.swiper-pagination-bullet-active {
            background-color: $oil;
        }
    }
}
.home-pagination {
    padding: 35px 0;
}
.homepage-banner-pagination {
    display: none;
    @media (max-width: $tablet-sm) {
        display: flex;
    }
}

.laptop-view {
    display: none;
    @media (max-width: $tablet-lg) {
        display: block;
    }
}

.tablet-view {
    display: none;
    @media (max-width: $tablet) {
        display: block;
    }
}
.desktop-view {
    display: block;
    @media (max-width: $tablet-lg) {
        display: none;
    }
}

.small-desktop-view {
    display: block;
    @media (max-width: $tablet) {
        display: none;
    }
}

/*swiper arrows*/
.swiper-button-prev {
    width: 63px!important;
    height: 63px!important;
    &::after {
        display: inline-block;
        content: ""!important;
        background-image: url('../../../icons/carousel-arrow.svg');
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
        background-repeat: no-repeat;
        width: 63px;
        height:  63px;
        @media (max-width: $laptop-sm) {
            background-image: url('../../../icons/carousel-arrow-md.svg');
            width: 50px;
            height:  50px;
        }
    }
    @media (max-width: $tablet-sm) {
        display: none!important;
    }
}
.swiper-button-next {
    width: 63px!important;
    height: 63px!important;
    &::after {
        display: inline-block;
        content: ""!important;
        background-image: url('../../../icons/carousel-arrow.svg');
        background-repeat: no-repeat;
        width: 63px;
        height:  63px;
        @media (max-width: $laptop-sm) {
            background-image: url('../../../icons/carousel-arrow-md.svg');
            width: 43px;
            height:  43px;
        }
    }
    @media (max-width: $tablet-sm) {
        display: none!important;
    }
}
.banner-slider {
    .swiper-button-prev, .swiper-button-next  {
        top: 40%;
        @media (max-width: $laptop-sm) {
            top: 40%;
        }
    }
}

/*homepage infos*/
.homepage-infos {
    background-image: url('../../../assets/images/bg-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    .grid-infos {
        text-align: center;
        padding: 65px var(--side-padding);
        max-width: 1660px;
        margin: 0 auto;
        justify-content: space-between;
        @media (max-width: $tablet) {
            max-width: 750px;
            flex-wrap: wrap;
        }
        .info-column {
            max-width: 310px;
            padding: 0 15px;
            @media (max-width: $tablet) {
                max-width: unset;
                flex-basis: 50%;
                &:nth-child(1),  &:nth-child(2) {
                    padding: 0 15px 35px;
                }
            }
            h4 {
                margin: 18px 0 14px;
                font-size: var(--text-small);
                text-transform: uppercase;
            }
            p {
                font-size: 1.3rem;
                color: $gray;
                line-height: 1.5;
            }
        }
    }
}

/*home categories*/
.home-categories-container {
    margin: 75px auto;
}
.categories-wrapper.flex-row {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
}
.categories-wrapper .category-item {
    position: relative;
}
.categories-wrapper .category-item a {
    color: #000;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    padding-bottom: 70px;
}
.categories-wrapper .category-item:first-child {
    margin-right: 15px;
}
.categories-wrapper .category-item:last-child {
    margin-left: 15px;
}
.categories-wrapper .category-item .category-info {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 30px 25px 25px;
    background-color: rgba(255, 255, 255, 1);
    width: calc(100% - 8.333vw);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.categories-wrapper .category-item a:hover .category-info {
    bottom: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.categories-wrapper .category-item .title {
    justify-content: space-between;
    align-items: center;
}
.categories-wrapper .category-item .title h3 {
    max-width: calc(100% - 120px);
}
.categories-wrapper .category-item .title .category-btn {
    font-size: 1.4rem;
    font-weight: 600;
}
.categories-wrapper .category-item .title .category-btn:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background-color: #000;
    position: relative;
    bottom: -3px;
}
/*home carousel tabs*/
.carousel-container {
    margin: 100px auto;
    padding: 0 30px;
}
.carousel-container .nav-tabs {
    text-align: center;
    border-bottom: none;
    margin-bottom: 30px;
}
.carousel-container .nav-tabs > li {
    float: unset;
    display: inline-block;
}
.carousel-container .nav-tabs > li a {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #000;
    border: none;
    padding: 10px 25px;
    margin: 0;
}
.carousel-container .nav-tabs > li.active a {
    font-size: 2.4rem;
    line-height: 2;
    font-weight: 600;
    color: #000;
    border: none;
}
.carousel-container .nav-tabs > li a:focus,
.carousel-container .nav-tabs > li a:hover {
    background-color: transparent;
}
.carousel-container .nav-tabs > li.active a:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background-color: #000;
    position: relative;
    bottom: -3px;
}

/*carousel slick dots*/
.carousel-container .tab-content .tab-pane {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
}
.carousel-container .tab-content .tab-pane.active {
    height: auto;
}
.carousel-container .tab-content .tab-pane .carousel-wrapper {
    position: relative;
    padding-bottom: 55px;
}
.carousel-container .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
.carousel-container .slick-dots li {
    display: inline-block;
    margin: 0 10px;
}
.carousel-container .slick-dots li button {
    font-size: 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 0;
    background-color: #c8c8c9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
}
.carousel-container .slick-dots li.slick-active button {
    background-color: #000;
}

/*carousel slick arrows */
.carousel-arrows {
    display: none;
}
.show-slider-nav + .carousel-arrows {
    display: block;
}
.carousel-container .carousel-arrows {
    position: absolute;
    left: 50%;
    bottom: 15px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 190px;
    width: 100%;
}
.carousel-container .carousel-arrows .arrow,
.carousel-container .carousel-arrows .arrow svg {
    display: block;
    width: 30px;
    height: 9px;
    cursor: pointer;
}
.carousel-container .carousel-arrows .arrow {
    position: absolute;
}
.carousel-container .carousel-arrows .arrow.next {
    right: 0;
}

/*home more categories*/
.more-category-wrapper.flex-row {
    justify-content: center;
}
.home-more-categories {
    padding: 0 15px;
    padding-bottom: 40px;
}
.home-more-categories h2 {
    margin-bottom: 50px;
}
.home-more-categories .category-item:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.more-category-wrapper .category-item {
    position: relative;
    margin: 0 15px;
}
.more-category-wrapper .category-info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 25px 30px;
    width: 100%;
    z-index: 1;
}
.more-category-btn {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    padding: 15px 25px;
    position: relative;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.category-item a:hover .more-category-btn {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.more-category-wrapper .category-item img {
    align-self: center;
}

@media (max-width: $laptop-m) {
    /*homepage*/
    .more-category-wrapper .category-info {
        padding: 20px;
    }
    .more-category-wrapper .category-info h3 {
        font-size: 2.5rem;
    }
    .more-category-btn {
        padding: 10px 20px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 2rem;
    }
}

@media (max-width: $tablet-lg) {
    /*homepage*/
    .home-categories-container {
        margin: 25px auto 75px;
    }
    .categories-wrapper .category-item .category-info {
        width: calc(100% - 3.333vw);
        padding: 20px;
    }
    .more-category-wrapper.flex-row {
        flex-wrap: wrap;
    }
    .more-category-wrapper .category-item {
        margin-bottom: 30px;
        width: calc(50% - 30px);
    }
    .more-category-wrapper .category-item a {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: $tablet) {
    /*homepage*/
    .home-categories-container {
        margin-bottom: 55px;
    }
    .categories-wrapper .category-item .title h3 {
        font-size: 1.5rem;
        max-width: 100%;
    }
}

@media (max-width: $tablet-sm) {
    /*homepage*/
    .categories-wrapper.flex-row {
        flex-direction: column;
        padding: 0 15px;
    }
    .home-categories-container {
        margin-bottom: 35px;
    }
    .categories-wrapper .category-item:first-child,
    .categories-wrapper .category-item:last-child {
        margin: 0 auto;
    }
    .categories-wrapper .category-item + .category-item {
        margin-top: 15px;
    }
    .categories-wrapper .category-item .category-info {
        width: calc(100% - 15vw);
        padding: 10px;
        text-align: center;
    }
    .categories-wrapper .category-item .title h3 {
        margin: 5px auto;
    }
    .categories-wrapper .category-item .title {
        flex-direction: column;
    }
    .home-more-categories {
        padding-bottom: 0;
    }
    .home-more-categories h2 {
        font-size: 2rem;
    }
    .more-category-wrapper .category-item {
        width: calc(50% - 30px);
        margin: 0 auto 15px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 1.6rem;
        padding: 15px;
    }
}

@media (max-width: $mobile) {
    /*homepage*/
    .carousel-container {
        margin-top: 20px;
        padding: 0 15px;
    }
    #subscribe-popup {
        padding: 55px 40px 40px 45px;
    }
    .carousel-container .nav-tabs {
        margin-bottom: 20px;
    }
    .carousel-container .nav-tabs > li a {
        font-size: 1.5rem;
        padding: 10px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 1.6rem;
    }
    #newsletter-popup img {
        display: none;
    }
}
#newsletter-popup{
    opacity: 0;
}
.footer-newsletter#newsletter-popup {
    background: #fff;
    max-width: 600px;
    padding: 30px;
}
.lity.lity-opened.lity-inline {
    background: rgba(0,0,0,0.5);
}
button.lity-close {
    position: absolute;
}
.newsletter-box{
    display: none;
}

#newsletter-popup h4.footer-title {
    font-size: 32px;
    line-height: 40px;
    font-family: Legan, sans-serif;
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: 0;
}
#newsletter-popup p.newsletter-promo {
    font-size: 19px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 60px;
}
.lity-close:hover,
.lity-close:focus,
.lity-close:active,
.lity-close:visited,
.lity-close {
    width: 17px;
    height: 17px;
    font-size: 28px;
    line-height: 17px;
    top: 17px;
    right: 17px;
    text-shadow: none;
    color: #000;
}
#newsletter-popup .newsletter-submit-arrow {
    display: none;
}
#newsletter-popup a.newsletter-subscribe-btn {
    position: relative;
    top: 0;
    right: 0;
    display: block;
    background: #6a6b59;
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 15px;
    color: #fffefe;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
a.newsletter-subscribe-btn:hover {
    cursor: pointer;
}
#newsletter-popup .newsletter-wrapper input[type="text"] {
    font-size: 1.3rem;
    letter-spacing: 0;
    font-weight: 400;
    padding: 4px 20px;
    margin-bottom: 20px;
    border: 1px solid #000;
}
.newsletter-wrapper input[type="text"]{
    position: relative;
    width: 100%;
    background: transparent;
    border: none;
    font-size: 1.4rem;
    line-height: 3.6rem;
    font-weight: 400;
    letter-spacing: 0.025em;
    color: #000000;
    padding: 8px 60px 8px 20px;
}
#newsletter-popup .newsletter-wrapper input[type="submit"]::placeholder {
    font-size: 1.3rem;
    letter-spacing: 0;
}
#newsletter-popup .newsletter-wrapper input[type="text"]::placeholder {
   color: #000;
}
#newsletter-popup .newsletter-wrapper {
    border: none;
}
#newsletter-popup {
    padding: 0 !important;
    max-width: 815px !important;
    background: #faf7f3 !important;
}
a.newsletter-subscribe-btn {
    position: absolute;
    top: 3px;
    right: 3px;
    background-color: #232323;
    padding: 17px 20px;
}
#subscribe-popup {
    padding: 55px 40px 40px 45px;
}
#newsletter-popup p.newsletter-promo.discount-rule {
    font-size: 1.2rem;
    margin-bottom: 0;
    line-height: 1.7rem;
}
#newsletter-popup img {
    max-width: 380px;
    padding: 25px;
    max-height: 550px;
}
/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
/*breadcrumb*/
.breadcrumb {
    text-align: center;
    margin: 0;
    padding: 30px 0 15px 0;
    border: none;
    background-color: transparent;
    li {
        padding: 0;
        a {
            text-transform: uppercase;
            display: inline-block;
            font-size: 1.3rem;
            font-weight: 400;
            color: $black;

        }
        &::after {
            content: '/';
            display: inline-block;
            margin: 0 7px 0 10px;
        }
        &:last-child {
            a {
                color: $oil;
                font-weight: 500;
                text-decoration: underline;
            }
            &::after {
                display: none;
            }
        }
        & + li {
            &:before {
                display: none;
            }
        }
    }
}

/*category-container*/
.category-container {
    padding: 60px var(--side-padding) 7.292vw;
    @media (max-width: $tablet) {
        padding: 30px 10px 50px;
    }

    .category-info {
        text-align: center;
        opacity: 0;
        -webkit-transition: opacity 0.2s ease;
        -moz-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease;
        h1.header-1 {
            text-transform: uppercase;
        }
    }
    .products-filters {
        opacity: 0;
        -webkit-transition: opacity 0.2s ease;
        -moz-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease;
    }
    .products-grid {
        flex-wrap: wrap;
        .product-col {
            flex-basis: calc(25% - 3px);
            margin: 0 1.5px 5.208vw;
            overflow: hidden;
            @media (max-width: $laptop-xs) {
                flex-basis: calc(33.333% - 3px);
            }
            @media (max-width: $tablet) {
                margin-bottom: 3px;
                background-color: $beige;
            }
            @media (max-width: $tablet-sm) {
                flex-basis: calc(50% - 3px);
            }
            .product-thumb {
                width: unset;
                max-width: unset;
                a {
                    @media (max-width: $tablet) {
                        background-color: $beige;
                    }
                }
                .product-card-arrow {
                    opacity: 0;
                    visibility: hidden;
                    background-color: unset;
                    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                    position: absolute;
                    top: 50%;
                    max-width: 50px;
                    z-index: 10;
                    cursor: pointer;
                    display: block;
                    @media (max-width: 1540px) {
                        max-width: 40px;
                    }
                    @media (max-width: $tablet) {
                        display: none !important;
                        opacity: 1;
                        visibility: visible;
                        max-width: 30px;
                    }
                    &.product-card-arrow-prev {
                        left: 10px;
                        right: auto;
                    }
                    &.product-card-arrow-next {
                        right: 10px;
                        left: auto;
                    }
                }
                &:hover {
                    .product-card-arrow {
                        opacity: 1;
                        visibility: visible;
                        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}

/*product-thumb*/
.product-thumb {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    overflow: initial;
    border: none;
    .image {
        position: relative;
        overflow: hidden;
        a {
            display: block;
            background-color: $light-beige;
            img {
                mix-blend-mode: darken;
            }
            @media (max-width: $tablet) {
                background-color: transparent;
            }
        }
        .product-badges {
            position: absolute;
            bottom: 0;
            left: 10px;
            margin-bottom: 20px;
            @media (max-width: $tablet) {
                margin-bottom: 5px;
            }
            li {
                margin-bottom: 5px;
                &:not(:first-child) {
                    margin-top: 10px;
                    @media (max-width: $tablet-sm) {
                        margin-top: 0;
                    }
                }
                &:not(:last-child) {
                    @media (max-width: $tablet-sm) {
                        margin-right: 5px;
                    }
                }
                @media (max-width: $tablet-sm) {
                    display: inline-block;
                }
                .badge {
                    display: inline-block;
                    width: 70px;
                    text-align: center;
                    font-size: 1.3rem;
                    line-height: 1.5;
                    font-weight: 500;
                    padding: 5px 10px;
                    border-radius: 3px;
                    @media (max-width: $mobile) {
                        width: 65px;
                        padding: 5px;
                    }
                    &.prd-dimension {
                        color: $black;
                        background-color: $light-brown;
                        border: 1px solid $light-brown;
                        margin: 0;
                    }
                    &.discount-percentage {
                        color: $white;
                        background-color: $red;
                        border: 1px solid $red;
                        margin: 0;
                    }
                    &.new {
                        color: $black;
                        background-color: #ececec;
                        border: 1px solid #ececec;
                        margin: 0;
                        @media (max-width: $tablet) {
                            margin-bottom: 3px;
                            background-color: $light-beige;
                            border: 1px solid $light-beige;
                        }
                    }
                }
            }
        }
    }
    .caption {
        padding: 30px 10px 10px;
        min-height: 150px;
        @media (max-width: $laptop-sm) {
            padding: 15px 10px 10px;
        }
        .prd-title {
            min-height: 48px;
            a {
                display: inline-block;
            }
            @media (max-width: $mobile) {
                font-size: 1.5rem;
                min-height: 60px;
                margin-bottom: 0;
            }
            @media (max-width: $mobile-md) {
                font-size: 1.3rem;
            }
        }
        .product-description {
            display: none;
        }
        .flex-row {
            align-items: center;
        }
    }
}

/*available colors*/
.product-available-colors {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    min-height: 23px;
    li {
        &:not(:last-child) {
            margin-right: 5px;
        }
        img {
            border-radius: 50%;
            max-width: 15px;
        }
    }
}

/*pagination*/
.category-pagination {
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    .pagination {
        margin: 0;
        border-radius: 0;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        li {
            display: inline-block;
            width: 36px;
            height: 36px;
            position: relative;
            margin: 0 5px;
            a {
                font-size: 1.3rem;
                font-weight: 400;
                color: $black;
                background-color: transparent;
                border: none;
                padding: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            &.active {
                background-color: $beige;
                border: 1px solid $beige;
                border-radius: 50%;
                span {
                    font-size: 1.3rem;
                    font-weight: 400;
                    color: $black;
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    span.arrow-left-start {
        display: none;
    }
    span.arrow-right-end {
        display: none;
    }
    span.arrow-next {
        display: block;
        content: '';
        background-image: url('../../../icons/default-arrow.svg');
        width: 35px;
        height: 12px;
        background-position: center;
    }
    span.arrow-prev {
        display: block;
        content: '';
        background-image: url('../../../icons/default-arrow.svg');
        width: 35px;
        height: 12px;
        background-position: center;
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}

/*brainyfilter*/
.box-heading {
    display: none;
}
.bf-float-submit {
    background-color: $white !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-color: $light-brown !important;
    background-color: $white !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    width: 100px !important;
    border: none !important;
    padding: 0 !important;
    .bf-tick {
        display: none !important;
    }
    .btn-primary {
        width: 100%;
    }
    @media (max-width: $tablet) {
        width: 40% !important;
        position: fixed !important;
        bottom: 0 !important;
        top: unset !important;
        left: 0 !important;
        z-index: 99999 !important;
    }
    @media (max-width: $tablet-sm) {
        width: 100% !important;
    }
}
.bf-close-btn {
    background-image: url('../../../icons/close-icon.svg') !important;
    display: none !important;
    background-image: url('../../../icons/close-icon.svg') !important;
    background-size: 16px 16px !important;
    background-position: center !important;
}
.products-filters {
    padding: 50px 0 75px;
    visibility: hidden;
    animation: fade-filters 1s ease-in 1s forwards;
    .products-filters-wrapper {
        justify-content: center;
        align-items: center;
        position: relative;
        .close-filters {
            display: none;
        }
        .filter-icon {
            margin-right: 35px;
        }
        .list-group {
            display: none;
        }
        .bf-form {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            @media (max-width: $tablet) {
                flex-direction: column;
                align-content: flex-start;
                align-items: unset;
                padding: 60px 10px 20px;
            }
            .bf-attr-block {
                margin-right: 35px;
                @media (max-width: $tablet) {
                    flex-basis: 100%;
                    width: 100%;
                    margin-right: 0;
                }
                .bf-attr-header {
                    font-size: 1.4rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    background-color: $beige;
                    border: 1px solid $beige;
                    border-radius: 5px;
                    padding: 10px 40px 10px 15px;
                    position: relative;
                    @media (max-width: $tablet) {
                        padding: 16px 20px 15px 10px;
                        border-bottom: 1px solid $black;
                        border-radius: 0;
                    }
                    .bf-arrow {
                        display: inline-block;
                        content: '';
                        width: 9px;
                        height: 5px;
                        float: unset;
                        position: absolute;
                        top: 55%;
                        right: 10px;
                        -webkit-transform: translateY(-55%);
                        -moz-transform: translateY(-55%);
                        -o-transform: translateY(-55%);
                        transform: translateY(-55%);
                        background-image: url('../../../assets/icons/new-arrow-down.svg') !important;
                        background-repeat: no-repeat !important;
                        background-position: 50% 50% !important;
                        background-size: 10px !important;
                    }
                }
                &:first-of-type {
                    .bf-attr-header {
                        border-width: 1px !important;
                    }
                }
                .bf-sliding-cont {
                    position: absolute;
                    width: 100%;
                    background: $white;
                    z-index: 9;
                    overflow-y: auto;
                    max-height: 320px;
                    margin-top: 5px;
                    @media (max-width: $tablet) {
                        position: relative;
                    }
                    .bf-sliding {
                        display: none;
                        transition: height 0.3s ease;
                        padding: 25px 15px;
                        &.bf-expanded {
                            transition: height 0.3s ease;
                            display: block;
                            height: 100% !important;
                        }
                        .bf-attr-block-cont {
                            padding: 0;
                            .bf-attr-filter {
                                .bf-cell {
                                    border-bottom: none;
                                    padding: 10px 5px;
                                    font-size: 1.4rem;
                                    font-weight: 400;
                                    label {
                                        font-weight: 400;
                                    }
                                    &.bf-c-3 {
                                        display: none;
                                    }
                                }
                                &.bf-disabled {
                                    color: $black;
                                    opacity: 0.2;
                                }
                            }
                        }
                    }
                }
                .bf-count {
                    display: none;
                }
            }
        }
        .box {
            border: none;
            background-color: transparent;
            border-radius: 0;
            margin: 0;
        }
        .bf-buttonclear-box {
            display: none;
        }
    }
}

.categories-choices {
    align-items: center;
    padding: 30px 0 40px;
    .filters-btn {
        .filter-icon {
            position: relative;
            top: 2px;
        }
    }
}

.category-short-by {
    .form-group {
        margin: 0;
        label {
            display: none;
        }
        #input-sort {
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: uppercase;
            border: none;
            box-shadow: none;
            border-radius: 0;
            height: unset;
            padding: 10px 40px 10px 15px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }
}

.price {
    color: $oil;
    margin-bottom: 5px;
}
.price-old {
    color: $oil;
    margin-left: 10px;
    text-decoration: line-through;
}
.price-new {
    color: $red;
}
.add-to-wishlist-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 30px;
    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        #border-2 {
            fill: $black;
        }
        #border-1 {
            fill: $black;
        }
        #filling {
            fill: none;
        }
    }
}
.is-favorite {
    .add-to-wishlist-icon {
        svg {
            #filling {
                fill: $black;
            }
        }
    }
}

.bf-cell.bf-cascade-1 {
    background-image: none;
}

@media (max-width: $tablet) {
    .products-filters {
        position: fixed;
        top: 0;
        left: -100%;
        opacity: 0;
        visibility: hidden;
        z-index: 99999;
        width: 40%;
        height: 100vh;
        padding: 0;
        background-color: $beige;
        -webkit-transition: all 0.9s ease;
        -moz-transition: all 0.9s ease;
        -o-transition: all 0.9s ease;
        transition: all 0.9s ease;
        @media (max-width: $tablet-sm) {
            width: 100%;
        }
        &.active {
            left: 0;
            opacity: 1;
            visibility: visible;
            -webkit-transition: left 0.4s ease;
            -moz-transition: left 0.4s ease;
            -o-transition: left 0.4s ease;
            transition: left 0.4s ease;
            .products-filters-wrapper {
                display: block;
                position: relative;
                padding: 60px 10px 20px;
                flex-direction: column;
                height: calc(100vh - 60px);
                justify-content: flex-start;
                overflow-y: auto;
                .filters {
                    display: block;
                    width: 100%;
                    .bf-form {
                        flex-direction: column;
                        align-content: flex-start;
                        align-items: unset;
                        padding: 0;
                    }
                }
                .close-filters {
                    display: block;
                }
                .filter-icon {
                    display: none;
                }
                .category-short-by {
                    width: 100%;
                    display: block;
                    .form-group {
                        width: 100%;
                        border-bottom: 1px solid $black;
                        #input-sort {
                            background-position: calc(100% - 15px) center;
                        }
                    }
                }
                .close-filters-icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    z-index: 99;
                    svg {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        .st0 {
                            fill: $black;
                        }
                    }
                }
            }
        }
        .products-filters-wrapper {
            display: none;
        }
    }

    .additional-filter-container .additional-filters-row{
        margin-top: -40px !important;
        margin-bottom: 40px !important;
    }

    /*filters*/
    .filters-btn {
        color: $black;
        font-size: 1.1rem;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        max-width: 100px;
        margin: 0 auto;
        border: 1px solid $black;
        padding: 6px 10px;
        align-items: center;
        justify-content: center;

        .text-icon {
            display: inline-block;
            margin-left: 7px;
        }
    }
    .filters-btn:hover,
    .filters-btn:active,
    .filters-btn:focus {
        color: #000;
    }

    body.category-filters-open {
        overflow: hidden;
    }
}
@keyframes fade-filters {
    from {
        visibility: hidden;
    }
    to {
        visibility: visible;
    }
}

.page_category .bf-attr-block:nth-of-type(1) {
    display: none;
}

.page_category.page_nees_paralaves .bf-attr-block:nth-of-type(1) {
    display: block;
}

.page_category.page_sales .bf-attr-block:nth-of-type(1) {
    display: block;
}
.additional-filter-container{
    max-width: 700px;
}
.additional-filters-row{
    padding: 25px;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 40px;
}
.additional-filter-btn{
    display: inline-block;
    /* width: 70px; */
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 3px;
    margin: 10px;
    color: var(--white);
}
.additional-filter-btn.sales{
    background-color: var(--red);
    border: 1px solid var(--red);
}
.additional-filter-btn.new_arrivals{
    background-color: #6e6f5e;
    border: 1px solid #6e6f5e;
}
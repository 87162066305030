/* cart page */
#checkout-cart {
    #content {
        margin-top:35px;
    }
    .mini-cart-total {
        font-size: 1.4rem;
        text-align: right;
    }
    .mini-cart-checkout {
        background-color: transparent;
        justify-content: flex-end;
        a {
            @media (max-width: $mobile) {
                max-width: 100%;
            }
        }
    }
    .cart-page {
        .mini-cart-total {
            max-width: 230px;
            margin: 30px 0 0 auto;
            @media (max-width: $tablet-sm) {
                max-width: 195px;
            }
            @media (max-width: $mobile) {
                max-width: 100%;
            }
            .col-total {
                justify-content: space-between;
                align-items: flex-end;
                font-family: var(--primary-font);
                font-size: 1.6rem;
                color: $black;
            }
            .cart-breakdown {
                width: 100%;
                margin-bottom: 20px;
            }
        }
        ul {
            li {
                border-bottom: 1px solid #ddd;
                margin-bottom: 11px;
                & + li {
                    margin-top: 10px;
                }
                .mini-cart-products {
                    padding: 20px 0;
                    justify-content: space-between;
                    align-items: center;
                    @media (max-width: $tablet-sm) {
                        align-items: unset;
                    }
                    .product-img {
                        flex-basis: 15%;
                        margin-right: 10px;
                        background-color: $light-beige;
                        @media (max-width: $tablet) {
                            flex-basis: 25%;
                        }

                        a {
                            display: inline-block;
                            .img-thumbnail {
                                border: none;
                                mix-blend-mode: darken;
                                padding: 0;
                            }
                        }
                    }
                    .prd-item {
                        flex-basis: 55%;
                        span {
                            display: inline-block;
                            font-size: 1.4rem;
                        }
                        @media (max-width: $mobile) {
                            flex-basis: 70%;
                        }
                        a {
                            display: block;
                            margin-bottom: 0;
                            font-size: 2rem;
                            @media (max-width: $mobile) {
                               font-size: 1.6rem;
                            }
                        }
                        .prd-description, .prd-price {
                            margin-bottom: 0;
                            font-size: 1.4rem;
                        }
                        .product-quantity {
                            &.mobile-view {
                                display: none;
                                @media (max-width: $tablet-sm) {
                                   display: block;
                                }
                            }
                        }
                    }
                    .product-remove {
                        align-self: start;
                        margin-left: 15px;
                        .remove-cart-item {
                            background: transparent;
                            border: none;
                        }
                    }
                    .product-quantity {
                        flex-basis: 20%;
                        margin-right: 15px;
                        &.desktop-view {
                            display: block;
                            @media (max-width: $tablet-sm) {
                                display: none;
                            }
                        }

                        .quantity-input-field{
                            display: block;
                            .quantity {
                                display: flex;
                                justify-content: space-between;
                                flex-basis: 60%;
                                @media (max-width: $tablet) {
                                    flex-basis: 100%;
                                }
                                @media (max-width: $tablet) {
                                    max-width: 110px;
                                    margin-top: 20px;
                                }
                                button.btn.btn-primary {
                                    background: no-repeat;
                                    color: $primary_color;
                                    border: 0;
                                    text-shadow: none;
                                    box-shadow: none;
                                    padding: 0;
                                    text-decoration: underline;
                                    display: inline-block;
                                    position: relative;
                                    margin-left: 15px;
                                }

                                .quantity-wrapper {
                                    position: relative;
                                    width: 100%;
                                    input.form-control {
                                        box-shadow: none;
                                        border: 1px solid var(--light-gray);
                                        text-align: center;
                                    }
                                    .quantity-nav{
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        -webkit-transform: translateY(-50%);
                                        -moz-transform: translateY(-50%);
                                        -o-transform: translateY(-50%);
                                        transform: translateY(-50%);
                                        width: 100%;
                                        max-width: 75px;
                                        justify-content: space-between;
                                        .quantity-button {
                                            cursor: pointer;
                                            padding: 10px 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
    .product-grid, .choosegift, .giftavailable {
        display: none;
    }
}
button.remove-cart-item {
    border: none;
}
.flex-voucher {
    align-items: flex-end;
    margin-top: 14px;
    padding-bottom: 13px;
    border-bottom: 1px solid #b3b3b3;
    margin-bottom: 25px;
}
.remove-voucher {
    margin-left: auto;
}
.prd-item-info {
    padding-left: 10px;
}
.prd-name {
    font-family: var(--secondary-font), sans-serif;
    color: #000;
}
.prd-price-container {
    margin-left: auto;
    padding-right: 20px;
}
.prd-price {
    color: #000;
}
.prd-item-info .prd-name {
    text-align: left;
    padding-bottom: 19px;
}
.price-typo {
    letter-spacing: 0;
    font-size: 1.4rem;
}
.product-img {
    padding-left: 3px;
}
img.gift-card-img {
    padding-bottom: 0;
    height: auto;
    width: 89px;
    border: 1px solid var(--oil);
    border-radius: 4px;
}
.prd-price .light {
    letter-spacing: 0;
}
.voucher-checkout-cart {
    position: relative;
    border-bottom: 1px solid #ddd ;
    margin-bottom: 11px;
}
.voucher-checkout-cart .product-remove {
    position: absolute;
    right: 0;
}
.voucher-checkout-cart .prd-item-info {
    padding-left: 78px;
}
.voucher-checkout-cart .img-thumbnail {
    left: 20px;
    position: relative;
}
@media screen and (max-width: 1199px) {
    .voucher-checkout-cart .prd-item-info {
        padding-left: 42px;
    }
}
@media screen and (max-width: 991px) {
    .voucher-checkout-cart .prd-item-info {
        padding-left: 55px;
    }
}
@media screen and (max-width: 767px) {
    .voucher-checkout-cart .prd-item-info {
        margin-left: 40%;
    }
}
@media screen and (max-width: 660px) {
    .voucher-checkout-cart .prd-item-info {
        margin-left: 0;
    }
    .voucher-checkout-cart .img-thumbnail {
        max-width: 60%;
    }
}
@media screen and (max-width: 540px) {
    .voucher-checkout-cart .prd-item-info {
        padding-left: 10px;
    }
}
@media screen and (max-width: 430px) {
    .voucher-checkout-cart .img-thumbnail {
        left: 12px;
    }
    .voucher-checkout-cart .prd-item-info {
        padding-left: 3px;
    }
    .voucher-checkout-cart .product-img {
        width: 100px;
    }
    .voucher-checkout-cart .img-thumbnail {
        max-width: 70%;
    }
}
.voucher-checkout-cart button.remove-cart-item {
    background: transparent;
}
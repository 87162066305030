#cart {
    &.open {
        .minicart-body {
            right: 0;
            @media (max-width: $tablet-sm) {
                left: unset;
            }
        }
    }
    .minicart-body {
        display: block;
        position: fixed;
        z-index: 999;
        padding: 60px 50px 30px;
        background-color: $beige;
        border: none;
        min-width: 390px;
        max-width: 610px;
        width: 100%;
        transition: right 0.6s ease;
        right: -100%;
        top: -5px;
        bottom: 0;
        border-radius: 0;
        box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
        @media (max-width: $tablet-sm) {
            top: 0;
            right: -100%;
            box-shadow: unset;
            min-width: auto;
            max-width: unset;
            padding: 60px 10px;
            width: 100%;
            overflow-y: auto;
        }
        .mini-cart-title {
            font-family: $secondary_font;
            font-size: clamp(2.4rem, 1.5625vw, 3rem);
            font-weight: 500;
            color: $black;
            margin: 0 0 35px;
            text-align: left;
            @media (max-width: $tablet-sm) {
                text-align: center;
                margin: 0 0 20px;
            }
        }
        .close-mini-cart {
            position: absolute;
            top: 55px;
            right: 55px;
            cursor: pointer;
            @media (max-width: $tablet-sm) {
                top: 20px;
                right: 20px;
            }
        }
        .all-products-w-button {
            .scrollable-products {
                overflow-y: auto;
                max-height: calc(100vh - 345px);
                li {
                    margin-right: 10px;
                }
                &::-webkit-scrollbar {
                    width: 5px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                    background: $beige;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $primary_color;
                    border-radius: 3px;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: $primary_color;
                }
                li {
                    .mini-cart-products {
                        border-bottom: 1px solid $light_gray;
                    }
                    .mini-cart-products {
                        padding: 15px 10px 15px 0;
                        @media (max-width: $laptop-sm) {
                            padding: 10px 10px 10px 0;
                        }
                        .product-img {
                            flex-basis: 20%;
                            padding-right: 10px;
                            background-color: $beige;
                            a {
                                display: inline-block;
                                width: 100%;
                                .img-thumbnail {
                                    width: 100%;
                                    border: none;
                                    padding: 0;
                                    mix-blend-mode: darken;
                                }
                            }
                        }
                        .prd-item {
                            flex-basis: 55%;
                            font-size: 1.4rem;
                            font-weight: 400;
                            color: $black;
                            margin: 0;
                            text-align: left;
                            align-self: center;
                            .prd-name {
                                color: $black;
                                font-family: $secondary_font;
                                display: inline-block;
                                font-size: 1.6rem;
                                font-weight: 400;
                                margin: 0 0 10px;
                                @media (max-width: $laptop-sm) {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                        .product-quantity {
                            flex-basis: 25%;
                            display: flex;
                            flex-direction: column;
                            .prd-price {
                                font-size: 1.4rem;
                                letter-spacing: 0;
                                padding-top: 15px;
                                margin: 0 0 25px;
                                color: $black;
                            }
                            .remove-cart-item {
                                border: none;
                                background-color: transparent;
                            }
                            .quantity-minicart {
                                input {
                                    max-width: 70px;
                                    min-height: unset;
                                    height: 35px;
                                }
                            }
                        }
                        @media (max-width: $tablet-sm) {
                            flex-wrap: wrap;
                            .product-img {
                                flex-basis: 20%;
                            }
                            .prd-item {
                                flex-basis: 70%;
                            }
                            .product-quantity {
                                flex-basis: 100%;
                                padding-left: 20%;
                                .prd-price {
                                    padding-top: 0;
                                    margin: 0 0 10px;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
            .mobile-bottom-cart {
                padding: 20px 50px 0;
                position: absolute;
                bottom: 30px;
                width: 100%;
                left: 0;
                background-color: $beige;
                @media (max-width: $tablet-sm) {
                    padding: 0 10px;
                }
                .mini-cart-total {
                    width: 100%;
                    .col-total {
                        font-size: 1.3rem;
                        line-height: 2.4rem;
                        font-weight: 600;
                        letter-spacing: 0.022em;
                        background-color: #e4e4e4;
                        color: $primary_color;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 25px;
                        margin-bottom: 15px;
                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }
            }
        }
        .mini-cart-total {
            .general-total {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.6rem;
                padding: 10px 0;
                color: $black;
            }
        }
        .free-shipping-minicart-container {
            background-color: $light-brown;
            border-radius: 3px;
            width: 100%;
            padding: 15px 5px;
            margin: 0 0 10px;
            p {
                font-size: 1.3rem;
                line-height: 1.2;
                letter-spacing: 0;
                color: $black;
                margin: 0;
            }
        }
    }
}

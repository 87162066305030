/*========================================================*/
/*** BRANDS ***/
/*========================================================*/
#product-manufacturer h1 {
    font-size: 2.4rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 600;
    color: #000;
    margin: 15px 0;
}
ul.brand-anchor {
    padding-bottom: 30px;
}
ul.brand-anchor li {
    max-width: 30px;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 0 7px 7px 0;
    background-color: #f0f0f0;
}
ul.brand-anchor li > a {
    font-size: 1.4rem;
    line-height: initial;
    font-weight: 400;
    color: #000;
    display: block;
    width: 100%;
    padding: 7px 0;
}
ul.brand-anchor li:hover {
    background-color: #000;
}
ul.brand-anchor li:hover a {
    color: #fff;
}
.brand-letter {
    display: inline-block;
    width: 100%;
    padding: 0 0 20px;
    margin: 20px 0;
    position: relative;
    border-bottom: 1px solid #eee;
}
.letter_only {
    display: inline-block;
    float: left;
    width: 10%;
}
.brands_per_letter {
    display: inline-block;
    float: left;
    width: 90%;
}
#product-manufacturer .brand-name {
    margin: 0 0 20px 0;
    font-weight: 800;
}
.brands_per_letter a {
    color: #000;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 0.025em;
}

.register-success-container {
    padding: clamp(30px, 3.3854vw, 50px) clamp(60px, 13.5417vw, 260px) clamp(60px, 13.5417vw, 260px);
    @media (max-width: $tablet) {
        padding: clamp(30px, 3.3854vw, 50px) $side_padding clamp(60px, 13.5417vw, 260px);
    }
    .header-1 {
        margin: 25px 0 clamp(30px, 3.3854vw, 65px);
    }
    .register-success-wrapper {
        max-width: 1145px;
        width: 100%;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $tablet) {
            flex-direction: column-reverse;
        }
        .left {
            max-width: 600px;
            width: 100%;
            margin-right: 45px;
            @media (max-width: $tablet) {
                margin: 30px 0 0;
            }
        }
        .right {
            max-width: 460px;
            width: 100%;
            text-align: justify;
            @media (max-width: $tablet) {
                max-width: 600px;
            }
        }
    }
}

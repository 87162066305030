.menu-container {
    > li {
        &:not(:last-child) {
            margin-right: clamp(15px, 2.6042vw, 50px);
            @media (max-width: 1465px) {
                margin-right: 15px;
            }
            @media (max-width: 1045px) {
                margin-right: 8px;
            }
        }
        > a {
            display: inline-block;
            font-size: 1.6rem;
            font-weight: 500;
            color: $primary_color;
        }
    }
    &.submenu-header-container {
        > li > a {
            color: $black;
        }
    }
    &.submenu-container {
        > li {
            margin-right: 0;
            > a {
                font-size: 2rem;
                color: $black;
                @media (max-width: 1540px) {
                    font-size: 1.8rem;
                }
            }
        }
    }
    &.desktop {
        @media (max-width: $tablet) {
            display: none;
        }
    }
}

.simplebar-content {
    > li {
        > a {
            font-size: 2rem;
            font-weight: 500;
            color: $black;
            @media (max-width: 1540px) {
                font-size: 1.8rem;
            }
        }
    }
}

#burger-menu-trigger {
    display: none;
    @media (max-width: $tablet) {
        display: block;
    }
}

.mobile-account-items {
    display: none;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px;
    .language-switcher {
        .btn-group {
            button {
                color: $primary_color;
            }
            .language-select {
                svg {
                    fill: #6a6b59;
                }
            }
        }
        .dropdown-menu {
            button {
                color: $primary_color;
            }
        }
    }
    @media (max-width: $tablet) {
        display: flex;
    }
}

.close-submenu-2 {
    display: none;
    @media (max-width: $tablet) {
        display: block;
        position: absolute;
        left: 25px;
        top: 20px;
    }
}
.submenu {
    padding: calc((var(--header-height) / 2) + var(--promo-height) - 13px) clamp(20px, 3.9063vw, 75px) 0;
    background: $beige;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    transition: left 0.6s ease;
    z-index: 9999;
    max-width: clamp(400px, 33.5938vw, 645px);
    width: fit-content;
    @media (max-width: 1540px) {
        max-width: unset;
        width: fit-content;
    }
    @media (max-width: $laptop-sm) and (min-width: $tablet + 1px) {
        padding: 54px clamp(20px, 3.9063vw, 75px) 0;
    }
    @media (max-width: $tablet) {
        max-width: 40vw;
        width: 100%;
        padding: 65px 10px 0;
    }
    @media (max-width: $tablet-sm) {
        max-width: 100vw;
    }
    &.active {
        left: 0;
    }
    .close-submenu {
        position: absolute;
        right: 30px;
        top: clamp(55px, 5.2083vw, 100px);
        @media (max-width: $laptop-sm) {
            top: 30px;
        }
        @media (max-width: $tablet) {
            right: 22px;
            top: 18px;
        }
    }
    .submenu-header-container {
        margin: 0 0 clamp(30px, 3.6458vw, 70px);
        @media (max-width: $laptop-sm) and (min-width: $tablet + 1px) {
            margin: 0 0 20px;
        }
        @media (max-width: $tablet) {
            flex-direction: column;
            > li > a {
                font-size: 2.4rem;
            }
        }
    }
    .menu-parent {
        &:not(:last-child) {
            margin-bottom: 20px;
            @media (max-width: 1540px) {
                margin-bottom: 10px;
            }
        }
        @media (max-width: $tablet) {
            font-size: 1.8rem;
            padding: 10px 12px;
            color: var(--black);
            border-bottom: 1px solid var(--black);
            > a {
                display: inline-block;
                width: 100%;
                color: var(--black);
                position: relative;
                z-index: 1;
            }
            &:not(:last-child) {
                margin-right: unset;
                margin-bottom: unset;
            }
            &.with-submenu {
                position: relative;
                &.mobile-with-child {
                    &::after {
                        top: 25px;
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    background-image: url('../../../icons/arrow-down.svg');
                    width: 12px;
                    height: 12px;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                &.active {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .submenu-container {
        .menu-parent {
            border: none;
            &.with-submenu {
                &:after {
                    background-image: url('../../../icons/arrow-down.svg');
                    transform: rotate(-90deg);
                }
            }
        }
        &.general {
            @media (max-width: $tablet) {
                display: none;
            }
        }
    }
    hr {
        margin: 30px 0;
        border-top: 1px solid $light_brown;
        @media (max-width: $tablet) {
            display: none;
        }
    }
    .products-submenu {
        @media (max-width: $tablet) {
            max-height: 50vh;
            overflow-y: auto;
            height: 0;
        }
        @media (max-width: $mobile) {
            max-height: 250px;
        }
        &.active {
            padding: 10px;
            height: auto;
        }
    }
    .submenu-2-trigger {
        position: relative;
    }
    .submenu-2 {
        overflow-y: auto;
        background-color: $light_brown;
        padding: calc((var(--header-height) / 2) + var(--promo-height)) clamp(20px, 3.9063vw, 75px) 0;
        max-width: clamp(400px, 33.5938vw, 645px);
        width: fit-content;
        position: fixed;
        left: -100%;
        top: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.6s ease;
        z-index: 999;
        &.active {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.6s ease;
            left: calc(clamp(400px, 33.5938vw, 645px) - 3px);
            @media (max-width: $tablet) {
                max-width: 40vw;
                left: 39.5vw;
            }
            @media (max-width: $tablet-sm) {
                max-width: 100vw;
                width: 100vw;
                left: 0;
            }
        }
    }
}
@media (min-width: $tablet + 1px) {
    ul.menu-container.submenu-container.products-submenu {
        height: 100%;
        overflow-y: auto;
        max-height: calc(100% - 345px);
        margin-bottom: 40px;
    }
}

:root {
    // palette
    --black: #232323;
    --white: #fcfcf9;
    --gray: #636363;
    --light-gray: #b3b3b3;
    --beige: #efece8;
    --light-beige: #faf7f3;
    --oil: #6a6b59;
    --dark-oil: #3c3d32;
    --light-brown: #e3dbcf;
    --red: #c75959;
    --blue: #527bb9;

    // colors
    --primary-color: var(--oil);
    --secondary-color: var(--beige);
    --input-border-color: var(--oil);
    --input-border-color-focus: var(--oil);
    --input-placeholder-color: var(--light-gray);
    --background-color: var(--light-beige);
    --footer-background-color: var(--light-beige);
    --promo-bar-background-color: var(--oil);

    // fonts
    --primary-font: 'Futura Now';
    --secondary-font: 'Legan';

    --header-1: clamp(3.2rem, 2.2917vw, 4.4rem);
    --header-2: clamp(2.4rem, 1.4583vw, 2.8rem);
    --header-3: 1.5rem;
    --header-4: 1.4rem;
    --banner-title: clamp(3rem, 4.1667vw, 8rem);
    --hero-title: clamp(3rem, 2.6042vw, 5rem);
    --text: 1.5rem;
    --text-small: 1.2rem;
    --text-large: 1.8rem;
    --btn-font: 1.4rem;
    --input-font: 1.5rem;
    --modal-title: clamp(2.4rem, 1.8229vw, 3.5rem);

    // layout
    --input-border-radius: 0px;
    --button-border-radius: 0px;
    --side-padding: clamp(10px, 3.9063vw, 75px);
    --side-padding-header: clamp(10px, 3.9063vw, 75px);
    --side-padding-footer: clamp(10px, 3.9063vw, 75px);
    --side-padding-checkout: clamp(30px, 4.1667vw, 80px);
}

/*========================================================*/
/* Colors */
/*========================================================*/

$primary_color: var(--primary-color);
$secondary_color: var(--secondary-color);

$placeholder_color: var(--input-placeholder-color);
$border_color: var(--input-border-color);
$border_color_focus: var(--input-border-color-focus);

$btn_font_color: var(--white);
$btn_background_color: var(--oil);

$background_color: var(--background-color);
$footer_background_color: var(--footer-background-color);
$promo_bar_background_color: var(--promo-bar-background-color);

$black: var(--black);
$white: var(--white);
$gray: var(--gray);
$light_gray: var(--light-gray);
$beige: var(--beige);
$light-beige: var(--light-beige);
$oil: var(--oil);
$dark-oil: var(--dark-oil);
$light-brown: var(--light-brown);
$red: var(--red);
$blue: var(--blue);

/*========================================================*/
/* Fonts */
/*========================================================*/

$primary_font: var(--primary-font), sans-serif;
$secondary_font: var(--secondary-font), sans-serif;

$header_1_fs: var(--header-1);
$header_2_fs: var(--header-2);
$header_3_fs: var(--header-3);
$header_4_fs: var(--header-4);
$banner_title_fs: var(--banner-title);
$hero_title_fs: var(--hero-title);
$text_fs: var(--text);
$text_small_fs: var(--text-small);
$text_large_fs: var(--text-large);
$btn_fs: var(--btn-font);
$input_fs: var(--input-font);
$modal_title: var(--modal-title);

/*========================================================*/
/* Layout */
/*========================================================*/

$border_radius_input: var(--input-border-radius);
$border_radius_button: var(--button-border-radius);
$side_padding: var(--side-padding);
$side_padding_header: var(--side-padding-header);
$side_padding_footer: var(--side-padding-footer);
$side_padding_checkout: var(--side-padding-checkout);

/*========================================================*/
/* Breakpoints */
/*========================================================*/

$laptop-lg: 1600px;
$laptop: 1440px;
$laptop-m: 1366px;
$laptop-sm: 1280px;
$laptop-xs: 1140px;
$tablet-lg: 1024px;
$tablet: 991px;
$tablet-sm: 767px;
$mobile: 660px;
$mobile-md: 580px;
$mobile-sm: 421px;
$mobile-xs: 375px;

/*========================================================*/
/* Responsive Variables */
/*========================================================*/
:root {
    @media (max-width: $laptop-sm) {
        --text: 1.4rem;
    }
    @media (max-width: $tablet-lg) {
        --side-padding-footer: 15px;
    }
    @media (max-width: $mobile) {
        --input-font: 1.4rem;
    }
    @media (max-width: $mobile-sm) {
        --side-padding: 10px;
    }
}

/*========================================================*/
/* Design Specs */
/*========================================================*/

$design-desk-spec: 1920;
$design-mob-spec: 360;

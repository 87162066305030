#post {
    .section-wrap {
        &.the-post {
            width: 100%;
            max-width: 860px;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: vw(153px);

            @media screen and (max-width:$tablet) {
                padding-bottom: 110px;
            }

            .full-post-date {
                display: inherit;
                text-align: center;
                padding-top: vw(60px);

                @media screen and (max-width: $tablet) {
                    padding-top: 45px;
                }
            }

            h1 {
                @extend .header-1;
                text-align: center;
                padding-top: vw(64px);
                padding-bottom: vw(35px);
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;

                @media screen and (max-width: $tablet) {
                    padding-top: 35px;
                    padding-bottom: 35px;
                }
            }

            .post-main-image {
                margin-bottom: vw(60px);

                @media screen and (max-width: $tablet) {
                    margin-bottom: 20px;
                }
            }

            p {
                text-align: justify;
                margin-bottom: vw(35px);

                @media screen and (max-width: $tablet) {
                    margin-bottom: 35px;
                    padding-left: 8.5px;
                    padding-right: 8.5px;

                    &:empty {
                        display:none;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &>img {
                    margin-top: vw(15px);
                    margin-bottom: vw(15px);
                    margin-left: auto;
                    margin-right: auto;
                }

                a {
                    display: inline-flex;
                    align-items: center;


                    img {
                        margin-top: 7.5px;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}
.post-b-space{
    padding-bottom: vw(150px);
}
/*========================================================*/
/*** LOGIN ***/
/*========================================================*/
.block-alignment {
    max-width: 555px;
    margin-left: auto;
    margin-right: auto;

    &.height-align {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: ($tablet-sm + 1)) {
            min-height: 370px;
        }
    }
}
#account-forgotten {
    form {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
    }
}

#account-forgotten,
#common-success {
    #d_social_login {
        display: none;
    }
}

#account-login,
#account-register {
    #d_social_login {
        max-width: 400px;
        .d-social-login__button {
            .d-social-login__button__icon {
                font-size: 1.5rem;
                text-align: center;
                padding: 22px 20px;
                max-height: unset;
                background-color: $blue;
                @media (max-width: $tablet) {
                    font-size: 1.3rem;
                    padding: 12px 10px;
                }
            }
        }
    }
    @media (max-width: $tablet) {
        width: 100%;
    }
    .breadcrumb {
        display: none;
    }
    #content {
        padding-top: clamp(50px, 5.2083vw, 100px);
    }
    .well {
        // bootstrap reset
        @extend .block-alignment, .height-align;
        padding: 20px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        &.new-customer-form:before {
            content: '';
            width: 1px;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            @media (max-width: $tablet-sm) {
                content: none;
            }
        }
        & > form {
            width: 100%;
        }
        @media (max-width: $tablet-sm) {
            padding: 10px;
        }
    }
    .register-btn {
        width: 100%;
        // max-width: 200px;
        // margin: 25px auto 0;
    }
    .forgotten-pass {
        margin-top: 5px;
    }
}
/*========================================================*/
/*** SOCIAL LOGIN ***/
/*========================================================*/
#d_social_login {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto clamp(30px, 2.6042vw, 50px) auto;
    .d-social-login__button {
        display: block;
        width: 100%;
        color: $white;
        text-decoration: none;
        .d-social-login__button__icon {
            max-width: 100%;
            text-align: left;
            width: 100%;
            display: block;
            position: relative;
            padding-left: 45px;
            img {
                max-width: 30px;
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        &.d-social-login__button--facebook .d-social-login__button__icon img {
            max-width: 10px;
        }
        &.d-social-login__button--google span.d-social-login__button__icon img {
            max-width: 25px;
        }
    }
}

/*========================================================*/
/*** REST ACCOUNT PAGES ***/
/*========================================================*/
.common-account {
    padding: clamp(50px, 5.2083vw, 100px) 15px;
    float: none;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    &.large-container {
        max-width: 900px;
    }
    .breadcrumb {
        display: none;
    }
    .link-success {
        text-align: center;
        p {
            font-size: 1.6rem;
        }
    }
    legend {
        font-size: $text_fs;
        font-weight: 500;
        border-bottom: none;
        margin: 0 0 15px;
        text-transform: uppercase;
    }
    .terms {
        margin: 0 0 15px;
        display: flex;
        align-items: center;
    }
    .text-agree {
        padding-left: 5px;
        margin-bottom: 2px;
    }
    ul > li > a {
        line-height: 2.5;
        text-decoration: none;
    }
    .btn-default {
        text-decoration: none !important;
    }
    .col-sm-2.control-label {
        display: none;
    }
    .default-address,
    .is-opened {
        display: block !important;
        text-align: left;
        width: 100%;
    }
    .buttons {
        max-width: 600px;
        margin: auto;
        margin-top: 15px;
    }
    .col-sm-10 {
        display: block;
        width: 100%;
    }
    .table-bordered {
        > tbody > tr > td,
        > tbody > tr > th,
        > tfoot > tr > td,
        > tfoot > tr > th,
        > thead > tr > td,
        > thead > tr > th {
            border: none;
            border-bottom: 1px solid $border_color;
            padding: 20px 15px;
        }
    }

    td.text-left a {
        color: #000;
        text-decoration: none;
    }
    td.text-right > a {
        text-decoration: none;
    }
    .add-to-cart-icon svg,
    .add-to-cart-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
    .remove-item-icon svg .st0 {
        fill: none;
        stroke: #000000;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-miterlimit: 10;
    }
    .remove-item-icon {
        margin-left: 10px;
    }
    .wishlist-image {
        background-color: $light_beige;
        img {
            mix-blend-mode: multiply;
        }
    }
    thead > tr > td {
        font-size: 1.4rem;
        letter-spacing: 0.025em;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }
    tbody > tr > td {
        font-size: 1.4rem;
        font-weight: 400;
        color: #000;
    }
    .table-hover > tbody > tr {
        opacity: 0.7;
        background-color: transparent;
    }
    .table-hover > tbody > tr:hover {
        opacity: 1;
    }
    td.text-right > a.edit-address-btn,
    td.text-right > a.remove-address-btn,
    td.text-right > a.btn-view-item {
        font-size: 1.1rem;
        line-height: initial;
        font-weight: 600;
        letter-spacing: 0.025em;
        color: #000;
        border: none;
        border-radius: 0;
        background-image: none;
        text-transform: uppercase;
        text-decoration: underline;
    }
    &.account-voucher {
        .col-sm-2.control-label {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}
.page_checkout .common-account {
    max-width: 720px;
}
// NO IDEA FROM DOWN HERE
.container.common-account.small-container {
    max-width: 500px;
}

.input-group.date button.btn.btn-default {
    padding: 9px 20px;
}
label.col-sm-3.control-label.newsletter-text,
label.col-sm-4.control-label.newsletter-text {
    text-align: left;
}

.in-reg-terms {
    display: flex;
    align-items: center;
    input {
        margin-right: 5px;
        flex-basis: 10%;
        max-width: 15px;
        width: 100%;
    }
}

.account-block-wrap {
    @extend .block-alignment;
    margin-bottom: 30px;

    .header-1 {
        @extend .header-2;
    }
    h1,
    h2 {
        margin-bottom: 30px !important;
    }
}
.a-pointer {
    cursor: pointer;
}

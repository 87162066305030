.modal-open {
    overflow: hidden;
}
.modal-title {
    font-family: $primary_font;
    //font-size: $modal_title;
    font-weight: 500;
    font-size: 2.8rem;
}
.modal-body {
    padding: clamp(15px, 2.0833vw, 40px);
    h2,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $secondary_font;
        font-size: $header_1_fs;
        font-weight: 500;
    }
}
.modal-header {
    position: relative;
    .close {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: 0;
        transform: translateY(-50%);
    }
}
.modal-content {
    border-radius: 0;
    border: none;
    -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
    background-color: $white;
}

.faq-outer-container {
    #column-left {
        max-width: 200px;
        width: 100%;
    }
    .faq-menu {
        ul li {
            border-bottom: 1px solid $light-brown;
            padding: 5px 0;
        }
        a {
            font-size: 1.8rem;
            line-height: 1.2;
            font-weight: 400;
            &.active {
                color: $oil;
                font-weight: 500;
            }
            &:hover {
                color: $oil;
            }
        }
    }

    .faq-container {
        width: 100%;
        max-width: 915px;
        margin: 0 auto 0 clamp(30px, 5.2083vw, 100px);
        @media (max-width: $tablet-sm) {
            margin: 30px auto;
        }
    }
    .information-container {
        padding: 0 30px 100px;
        @media (max-width: $tablet-sm) {
            padding: 0 10px 50px;
            flex-direction: column;
        }
    }
}

.page_product {
    .theiaStickySidebar {
        background-color: $light_beige;
    }
    .product-container {
        padding: 40px $side_padding 0;
        margin: 0 0 clamp(60px, 11.4583vw, 220px);
        .breadcrumb {
            display: block;
        }
        @media (max-width: $tablet-sm) {
            padding: 0 10px;
        }
    }
    .product-row {
        @media (max-width: $tablet) {
            flex-direction: column;
        }
        .left-col {
            max-width: 1200px;
            width: 100%;
            padding: 0 30px 0 0;

            flex: 2;
            @media (max-width: $laptop-xs) {
                padding: 0 15px 0 0;
            }
            @media (max-width: $tablet) {
                max-width: unset;
                padding: 0;
                margin: 0 0 15px;
            }
        }
        .right-col {
            max-width: 540px;
            width: 100%;
            padding: 0 0 0 30px;
            flex: 1;
            @media (max-width: $laptop-xs) {
                padding: 0 0 0 15px;
            }
            @media (max-width: $tablet) {
                max-width: unset;
                padding: 0;
            }
            .product-badges {
                margin: 0 0 10px;
                display: flex;
                .badge {
                    display: inline-block;
                    width: 70px;
                    text-align: center;
                    font-size: 1.3rem;
                    line-height: 1.5;
                    font-weight: 500;
                    padding: 5px 10px;
                    border-radius: 3px;
                    margin-right: 10px;
                    &.prd-dimension {
                        color: $black;
                        background-color: $light-brown;
                        border: 1px solid $light-brown;
                    }
                    &.discount-percentage {
                        color: $white;
                        background-color: $red;
                        border: 1px solid $red;
                    }
                    &.new {
                        color: $black;
                        background-color: #ececec;
                        border: 1px solid #ececec;
                    }
                }
            }
        }
    }

    #product {
        padding-right: 30px;
        @media (max-width: $laptop-lg) {
            padding-right: unset;
        }
    }

    /*Product images*/
    .product-thumbnails {
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: $tablet) {
            mix-blend-mode: multiply;
            &.single-image {
                justify-content: center;
            }
        }
    }
    .product-thumbnails .thumb {
        flex-basis: 50%;
        margin-bottom: 30px;
        @media (max-width: $tablet) {
            flex-basis: unset;
            margin-bottom: 0;
        }
        a {
            display: inline-block;
            background: var(--beige);
            @media (max-width: $tablet) {
                width: 100%;
            }
        }
        img {
            mix-blend-mode: multiply;
            margin: 0 auto;
        }
    }
    .single-image.product-thumbnails .thumb {
        flex-basis: 100%;
    }
    .single-image.product-thumbnails .thumb img {
        margin: 0 auto;
    }
    .product-thumbnails .thumb:nth-child(odd) {
        padding-right: 15px;
        @media (max-width: $tablet) {
            padding-right: 0;
        }
    }
    .product-thumbnails .thumb:nth-child(even) {
        padding-left: 15px;
        @media (max-width: $tablet) {
            padding-left: 0;
        }
    }
    .product-row .slider-arrows {
        display: none;
    }
    .product-thumbnails .slick-dots {
        display: flex;
        margin: 15px auto 0;
        @media (max-width: $tablet) {
            margin: 5px auto 0;
        }
        li {
            display: block;
            border: 1px solid $light_gray;
            width: 16px;
            height: 16px;
            font-size: 0;
            border-radius: 50%;
            margin-right: 15px;
            cursor: pointer;
            &.slick-active {
                background-color: $primary_color;
            }
            button {
                opacity: 0;
            }
        }
    }

    /*Product details*/
    .product-code {
        margin: 0 0 5px;
        color: $light_gray;
        @media (max-width: $tablet) {
            display: none;
        }
    }
    .product-header {
        justify-content: space-between;
        align-items: center;
        .header-1 {
            margin: 0 0 15px;
            @media (max-width: $tablet) {
                text-align: left;
                font-size: 3rem;
                margin-bottom: 0;
                max-width: 600px;
            }
            @media (max-width: $mobile) {
                font-size: 2.3rem;
            }
        }
        .product-code {
            display: none;
        }
        @media (max-width: $tablet) {
            .product-code {
                display: block;
                text-align: left;
                margin-bottom: 10px;
            }
        }
    }

    .wishlist-btn {
        background-color: transparent;
        border: none;
    }
    .wishlist-fill-icon {
        display: block;
        width: 21px;
        svg {
            display: block;
            width: 21px;
            .st0 {
                fill: transparent;
                stroke: $black;
                stroke-width: 1px;
            }
            #border-2 {
                fill: $black;
            }
            #border-1 {
                fill: $black;
            }
            #filling {
                fill: none;
            }
        }
        &.active {
            svg {
                .st0 {
                    fill: $black;
                }
            }
        }
    }
    .is-favorite {
        .wishlist-fill-icon {
            svg {
                #filling {
                    fill: $black;
                }
            }
        }
    }
    .price-container {
        display: flex;
        justify-content: space-between;
        margin: 0 0 25px;
        .price-list {
            li {
                display: inline-block;
                padding-right: 15px;
            }
        }
        .product-code {
            display: none;
            @media (max-width: $tablet) {
                display: block;
            }
        }
    }

    .product-price {
        font-size: 1.8rem;
        color: $primary_color;
        @media (max-width: $mobile) {
            font-size: 2.2rem;
        }
        &.special {
            color: $red;
        }
        &.old {
            text-decoration: line-through;
            @media (max-width: $mobile) {
                font-size: 1.8rem;
            }
        }
    }
    .product-available-colors {
        display: flex;
        flex-direction: column;
        align-items: unset;
        .color {
            margin-bottom: 15px;
            font-size: 1.3rem;
            font-weight: 400;
        }
        .color-options li {
            max-width: 50px;
            width: 100%;
            margin-right: 10px;
            img {
                max-width: 100%;
                border-radius: unset;
                mix-blend-mode: multiply;
            }
            &.selected {
                border: 1px solid $black;
            }
        }
    }

    .product-info .table {
        margin-bottom: 0;
    }
    .product-info .table > thead > tr > td,
    .product-info .table > tbody > tr > td {
        padding-left: 0;
    }

    .select-options .control-label {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .quantity-input-field.show {
        display: block;
    }
    .quantity-input-field .number-input {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
    .quantity-input-field .number-input .control-label {
        flex-basis: 10%;
        border: 1px solid $black;
        margin-bottom: 0;
        padding: 12px 15px;
        border-left: none;
        border-right: none;
    }
    .select-options .form-control {
        border-radius: 0;
        height: 55px;
        font-size: 1.5rem;
        font-weight: 400;
        color: $black;
        border-color: $black;
        -webkit-appearance: none;
    }
    .select-options select.form-control {
        cursor: pointer;
        background-image: url('../../../icons/arrow-down.svg');
        background-repeat: no-repeat;
        background-position: 98% 50%;
    }
    .select-options .form-control:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-color: $black;
    }

    .select-options .quantity {
        position: relative;
    }
    .select-options .quantity input[type='text'] {
        padding-right: 30px;
        text-align: right;
        border: none;
        border-bottom: 1px solid $black;
        border-top: 1px solid $black;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-appearance: none;
    }
    .select-options .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 25px;
    }
    .select-options .quantity-nav.flex-column {
        align-items: center;
        justify-content: space-evenly;
    }
    .select-options .quantity-nav .quantity-button {
        color: $black;
        font-weight: 600;
        cursor: pointer;
    }

    .free-delivery-msg {
        font-size: 1.2rem;
        display: block;
        margin: 40px 0;
    }

    /*product info*/
    .product-info.panel-group .panel {
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: none;
        border-top: 1px solid #d6d6d6;
    }
    .product-info.panel-group .panel:last-child {
        border-bottom: 1px solid #d6d6d6;
    }
    .panel-group .panel + .panel {
        margin: 0;
    }
    .product-info .panel-heading {
        color: $black;
        padding: 25px 5px;
        background-color: transparent;
    }
    .product-info .panel-title > a {
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
    }
    .product-info .panel-default > .panel-heading + .panel-collapse > .panel-body {
        border: none;
        padding-top: 0;
    }
    .product-info .panel-body {
        padding-left: 5px;
    }
    .product-info .panel-body * {
        font-size: 1.3rem;
        letter-spacing: 0.035rem;
    }

    .product-tabs {
        margin: 0 0 20px;
        @media (max-width: $tablet) {
            text-align: center;
        }
        .product-tab-item .tab-heading {
            display: none;
        }
        .product-tabs-headings {
            margin: 15px 0 0;
            position: relative;
            @media (max-width: $mobile) {
                margin: 0;
            }
        }

        .tab-heading {
            display: inline-block;
            font-size: 1.2rem;
            font-weight: 500;
            text-transform: uppercase;
            color: $light_gray;
            &:hover,
            &.active {
                color: $black;
                font-weight: 500;
            }
            &:not(:last-child) {
                margin-right: clamp(25px, 2.6042vw, 50px);
            }
        }
        .product-tab-item:not(:last-child) .tab-heading {
            margin-right: 25px;
        }
        .tab-content {
            display: none;
            padding: 15px 30px 0 0;
            @media (max-width: $laptop-sm) {
                padding: 30px 20px 0 0;
            }
            @media (max-width: $tablet) {
                text-align: justify;
                padding: 10px 0 0;
            }
            &.active {
                display: block;
            }
            .panel-body {
                padding: 0;
            }
        }
    }

    /* =================== */
    .prd-buy {
        margin: 25px 0;
        max-width: 540px;
        width: 100%;
        .quantity-input-field {
            max-width: 75px;
            width: 100%;
        }
        .form-group {
            max-width: 360px;
            width: 100%;
            margin: 0 10px;
            .btn-default {
                padding: 10px;
                height: 55px;
            }
        }

        @media (max-width: $tablet) {
            max-width: unset;
        }
        @media (max-width: $tablet-sm) {
            flex-wrap: wrap;
            .quantity-input-field {
                order: 1;
                margin-right: 20px;
            }
            .wishlist-btn {
                order: 2;
                margin-left: 20px;
            }
            .form-group {
                order: 3;
                flex-basis: 100%;
                max-width: unset;
                margin: 25px 0 0;
            }
        }
    }

    .phone-support {
        margin: 0 0 15px;
        @media (max-width: $mobile) {
            margin: 0 0 10px;
        }
        .support-icon {
            position: relative;
            top: 5px;
            margin-right: 10px;
        }
    }

    .share-container {
        a {
            text-decoration: underline;
        }
    }

    /*related products*/
    .related-products {
        position: relative;
        margin: 0 0 clamp(80px, 8.5938vw, 165px);
        padding: 0 $side_padding;
        @media (max-width: $mobile) {
            .header-1 {
                font-size: 2.6rem;
            }
        }
        &.combine-with {
            border-top: none;
            margin-top: 0;
            margin-bottom: 120px;
        }
        @media (max-width: $tablet) {
            .product-thumb {
                background-color: $beige;
            }
        }
    }

    /* SLIDER */
    .products-slider-container {
        position: relative;
        .products-slider {
            margin: 0 auto 40px;
        }
        .product-slider-arrows {
            @media (max-width: ($tablet-sm + 1px)) {
                display: none;
            }
            .product-slider-arrow {
                position: absolute;
                max-width: 63px;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                @media (max-width: $laptop-sm) {
                    max-width: 45px;
                }
                &.product-slider-prev {
                    left: -32px;
                }
                &.product-slider-next {
                    right: -32px;
                }
            }
        }
        .product-slider-pagination {
            display: none;
            @media (max-width: ($tablet-sm + 1px)) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .swiper-pagination-bullet {
                width: 16px;
                height: 16px;
                background-color: transparent;
                border: 1px solid $light_gray;
                &.swiper-pagination-bullet-active {
                    background-color: $primary_color;
                }
            }
        }
    }

    /* =================== */

    /*add to cart message*/
    @keyframes note {
        0% {
            margin-top: 25px;
            opacity: 0;
        }
        10% {
            margin-top: 0;
            opacity: 1;
        }
        90% {
            margin-top: 0;
            opacity: 1;
        }
        100% {
            margin-top: 25px;
            opacity: 0;
        }
    }
    .popup-alert {
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 50px;
        margin-bottom: 0;
        background-color: #fff;
        color: $black;
        border: none;
        border-radius: 0;
        z-index: 9999;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        animation: note 6500ms forwards;
        @media (max-width: $tablet-sm) {
            padding: 15px;
            width: 90%;
            margin: 0;
        }
        a,
        a:hover {
            color: $black;
            font-weight: 500;
        }
        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            color: inherit;
            @media (max-width: $tablet-sm) {
                top: 5px;
                right: 10px;
                svg {
                    width: 10px;
                }
            }
        }
    }

    @media (max-width: $tablet-sm) {
        /*product*/
        .product-row.flex-row {
            flex-direction: column;
        }
        .product-row .left-col {
            padding-right: 0;
        }
        .product-row .right-col {
            padding: 0;
            max-width: unset;
        }
        .product-thumbnails .thumb {
            margin-bottom: 0;
        }
        .product-thumbnails .thumb:nth-child(even),
        .product-thumbnails .thumb:nth-child(odd) {
            padding: 0;
        }
        .product-thumbnails .slick-slide img {
            margin: 0 auto;
        }
        .products-block .product-thumb {
            margin-bottom: 15px;
        }
        .flex-row.category-pagination {
            padding: 15px 0;
        }
    }
}

.quantity-input-field {
    &.minicart {
        display: flex;
        justify-content: flex-end;
    }
    .quantity,
    .quantity-minicart {
        position: relative;
    }
    .quantity-minicart {
        margin-right: 15px;
        .quantity-nav {
            width: 80%;
            top: 45%;
        }
    }
    .quantity-minicart input[type='text'].form-control,
    .quantity input[type='text'].form-control {
        border: 1px solid $light_gray;
        border-radius: $border_radius_input;
        padding: 10px 5px;
        text-align: center;
        max-width: 75px;
        min-height: 55px;
    }
    .quantity-nav {
        justify-content: space-between;
        position: absolute;
        width: 84%;
        top: 49%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .quantity-button {
            cursor: pointer;
        }
    }
}

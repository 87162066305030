.popup-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 50px;
  margin-bottom: 0;
  background-color: #fff;
  color: $black;
  border: none;
  border-radius: 0;
  z-index: 9999;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  animation: note 6500ms forwards;
  @media (max-width: $tablet-sm) {
    padding: 15px;
    width: 90%;
    margin: 0;
  }

  a,
  a:hover {
    color: $black;
    font-weight: 500;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: inherit;
    @media (max-width: $tablet-sm) {
      top: 5px;
      right: 10px;
      svg {
        width: 10px;
      }
    }
  }
}
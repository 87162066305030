/*========================================================*/
/* Layout*/
/*========================================================*/
.flex-row {
    display: flex;
}
.flex-column {
    display: flex;
    flex-direction: column;
}

.col-50 {
    flex-basis: 50%;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.overflow-hidden {
    overflow: hidden;
}

.float-right {
    float: right;
}

.side-padding {
    @include sidePadding($side_padding);
}

// reset helping classes
.m0 {
    margin: 0;
}

.mt0 {
    margin-top: 0;
}

.mb0 {
    margin-bottom: 0;
}

.p0 {
    padding: 0;
}

// Megamenu helping class
.mobile-account-items {
    display: none;
}

/*========================================================*/
/*** Default Elements ***/
/*========================================================*/
.btn-default {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 20px 15px;
    font-size: $btn_fs;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: $btn_font_color;
    background-color: $btn_background_color;
    border: 1px solid $btn_background_color;
    border-radius: $border_radius_button;
    background-image: none;
    text-decoration: none;
    line-height: 1.8;
    transition: ease 0.3s;
    min-height: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @media (max-width: $tablet) {
        padding: 10px 15px;
    }
    &:hover {
        color: $btn_background_color;
        background-color: transparent;
        border-color: $btn_background_color;
    }
    &.reverse {
        color: $btn_background_color;
        border-color: $btn_background_color;
        background-color: transparent;
        &:hover {
            background-color: $btn_background_color;
            border-color: $btn_background_color;
            color: $btn_font_color;
        }
    }
    &.light {
        color: $btn_font_color;
        border-color: $btn_font_color;
        background-color: transparent;
        &:hover {
            background-color: $btn_background_color;
            border-color: $btn_background_color;
        }
    }
    &.active {
        color: $white;
        background-color: $secondary_color;
        border-color: $secondary_color;
        &:hover {
            background-color: transparent;
            color: $btn_background_color;
        }
    }
    &.medium-button {
        max-width: 230px;
        @media (max-width: $tablet-sm) {
            max-width: 195px;
            padding: 10px 5px;
        }
    }
    &.small-button {
        max-width: 125px;
        padding: 10px;
    }
}

// Bootstrap defaults
.alert {
    margin: 15px;
}
.alert-success {
    color: $white;
    background-color: $black;
    border-color: $black;
    font-size: $text_fs;
    border-radius: 0;
    text-align: center;
}
.btn-primary,
.btn-info {
    font-size: $btn_fs;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: $white;
    background-color: $black;
    border: 1px solid $btn_background_color;
    border-radius: $border_radius_button;
    line-height: 1.8;
    transition: ease 0.3s;
    &:hover, &:focus, &:active{
        color: $white;
        background-color: $black!important;
        border-color: $black!important;
    }
}
.more-button {
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
    &::after {
        display: inline-block;
        content: ' ';
        background: url('../../../icons/arrow-next.svg');
        background-repeat: no-repeat;
        height: 10px;
        width: 10px;
        margin-left: 15px;
        transition: all 0.5s ease-out;
    }
    &:hover {
        &::after {
            margin-left: 30px;
            transition: all 0.5s ease-out;
        }
    }
}

.post-link-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &::after {
        content: '';
        width: vw(431px);
        @media screen and (max-width: $tablet) {
            width: 30vw;
        }
        @media screen and (max-width: $tablet-sm) {
            width: 45vw;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }
}

.post-link {
    display: flex;
    flex-direction: column;
    flex: 0 1 30%;
    margin-bottom: vw(163px);
    position: relative;

    .blog-img-wrapper {
        overflow: hidden;
        position: relative;
        .blog-img {
            height: clamp(450px, 29.688vw, 570px);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            transition: transform 400ms;
            &:hover {
                -webkit-transform: scale(1.07);
                -moz-transform: scale(1.07);
                -o-transform: scale(1.07);
                -ms-transform: scale(1.07);
                transform: scale(1.07);
                transition: all 400ms;
            }
        }
    }

    @media screen and (max-width: $tablet) {
        flex-basis: 32%;
        margin-bottom: 60px;
    }
    @media screen and (max-width: $tablet-sm) {
        flex-basis: 48%;
    }
    @media screen and (max-width: 500px) {
        flex-basis: 100%;
    }

    & > .date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 15px;
    }

    & > span {
        @media screen and (max-width: $tablet) {
            padding-left: 8.5px;
            padding-right: 8.5px;
        }
        @media screen and (max-width: $mobile-md) {
            text-align: center;
        }

        &:first-of-type {
            @extend .header-2;
            margin-top: vw(20px);
            margin-bottom: vw(20px);
            min-height: vw(67px);

            &._3-lines {
                min-height: vw(100px);
            }
            
            @media screen and (max-width: $tablet) {
                margin-top: 20px;
                margin-bottom: 15px;
            }
        }
    }
}

#relative-posts {
    @include page-sides-gap(244px, 10px, $tablet);
    overflow: hidden;

    .relative-posts-title {
        @extend .header-1;
        text-align: center;
        margin-bottom: vw(82px);

        @media screen and (max-width: $tablet) {
            margin-bottom: 45px;
        }
    }

    .relative-posts {
        width: 100%;

        .post-link {
            max-width: 100%;
            margin-bottom: 0;
        }
    }

    .relative-pagination {
        display: none;

        @media screen and (max-width: $tablet) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 60px;
            margin-bottom: 80px;
        }

        .swiper-pagination-bullet {
            background-color: transparent;
            width: 16px;
            height: 16px;
            border: 1px solid $light_gray;

            &.swiper-pagination-bullet-active {
                background-color: $oil;
                border-color: transparent;
            }
        }
    }
}

.content-hidden {
    display: none !important;
}

.single-heading-space {
    margin-bottom: vw(100px);

    @media screen and (max-width: $tablet) {
        margin-bottom: 50px;
    }
}

.fh-wo-header {
    min-height: calc(100vh - (var(--header-height) + var(--promo-height)));
}

// animations
.fade-in {
    opacity: 0;
}

.fade-children {
    > * {
        opacity: 0;
    }
}

// body cover
.body-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $black;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}
.overflow-hidden,
.cart-is-open {
    .body-cover {
        z-index: 99;
        opacity: 0.7;
        visibility: visible;
        transition: all 0.3s ease;
    }
}
#back-to-top {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: var(--oil);
    width: 130px;
    height: 50px;
    text-align: center;
    border-radius: 30px 0 0 30px;
    position: fixed;
    bottom: 100px;
    right: -85px;
    transition: right .3s,
    opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;

    &:hover {
        cursor: pointer;
        @media screen and (min-width: 768px) {
            right: 0;
        }

    }
    &:active {
        background-color: var(--dark-oil);
    }
    &.show-back-to-top {
        opacity: 1;
        visibility: visible;
    }
    .back-to-top-svg {
        margin-right: 15px;
        margin-bottom: 3px;
    }
    .back-to-top-text {
        font-size: 1.3rem;
        color: var(--white);
    }
}

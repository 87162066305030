//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}

// Side Padding mixin
@mixin sidePadding($side_padding) {
    padding-right: $side_padding;
    padding-left: $side_padding;
}

//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
}

// Prefix mixin
@mixin prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}

// Font face mixin
@mixin font-face($name, $file) {
    @font-face {
        font-family: '#{$name}';
        src: url('../fonts/#{$file}.eot');
        src: url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype'), url('../fonts/#{$file}.woff') format('woff'),
            url('../fonts/#{$file}.ttf') format('truetype'), url('../fonts/#{$file}.svg?#webfont') format('svg');
    }
}

// Show n lines of text (...)
@mixin shortText($lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$lines}; /* number of lines to show */
    line-clamp: #{$lines};
    -webkit-box-orient: vertical;
}

@function vw($target) {
    $vw-context: ($design-desk-spec * 0.01) * 1px;
    @return calc(($target / $vw-context) * 1vw);
}

@function vw-rs($screen, $target) {
    $vw-context: ($screen * 0.01) * 1px;
    @return calc(($target / $vw-context) * 1vw);
}


@mixin page-sides-fill($desk-size, $rs-size, $rs: null) {
    padding-left: vw($desk-size);
    padding-right: vw($desk-size);

    @media screen and (max-width:$laptop) {        
        padding-left: calc(vw($desk-size) / 2);
        padding-right: calc(vw($desk-size) / 2);
    }

    @if type-of($rs) != null {
        @media screen and (max-width: $rs) {
            padding-left: $rs-size;
            padding-right: $rs-size;
        }
    }
}
@mixin page-sides-gap($desk-size, $rs-size, $rs: null) {
    margin-left: vw($desk-size);
    margin-right: vw($desk-size);

    @media screen and (max-width:$laptop) {        
        margin-left: calc(vw($desk-size) / 2);
        margin-right: calc(vw($desk-size) / 2);
    }

    @if type-of($rs) != null {
        @media screen and (max-width: $rs) {
            margin-left: $rs-size;
            margin-right: $rs-size;
        }
    }
}
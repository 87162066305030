#main-header {
    padding: 10px $side_padding_header;
    justify-content: space-between;
    align-items: center;
    color: $primary_color;
    background-color: #fff;
    @media (max-width: 1214px) {
        padding: 10px $side_padding_header 10px 10px;
    }
    &.hidden-top {
        top: calc(-1 * (var(--header-height) + var(--promo-height)));
    }
    &.sticky {
        top: 0;
        position: fixed;
        opacity: 1;
        visibility: visible;
        transition: 0.3s ease;
    }
    .left-menu {
        flex-basis: 40%;
        white-space: nowrap;
        @media (max-width: 1214px) {
            flex-basis: 45%;
        }
    }
    .main-logo {
        flex-basis: 20%;
        align-self: center;
        @media (max-width: 1214px) {
            flex-basis: 15%;
        }
        #logo {
            margin: 0 auto;
            max-width: clamp(100px, 12.5vw, 240px);
            @media (max-width: $laptop-lg) {
                max-width: clamp(100px, 10.349vw, 160px);
            }
            a,
            svg {
                width: 100%;
            }
        }
    }
    .right-menu {
        flex-basis: 40%;
        #search {
            position: relative;
            .search-input-box {
                visibility: hidden;
                opacity: 0;
                position: fixed;
                width: 100%;
                top: 0;
                right: -100%;
                padding: 30px $side_padding;
                background: $white;
                height: calc(var(--header-height) + var(--promo-height));
                z-index: 999;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: right 0.4s ease, opacity 0.3s ease;
                &.active {
                    visibility: visible;
                    opacity: 1;
                    right: 0;
                    transition: left 0.4s ease, opacity 0.3s ease;
                }
                .search-input-container {
                    position: relative;
                    max-width: 450px;
                    width: 100%;
                    margin: 0 auto;
                }
                .close-search {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    cursor: pointer;
                    max-width: 18px;
                    z-index: 2;
                }
                .search-submit {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    z-index: 2;
                }
            }
            .form-control,
            #search-autocomplite {
                display: block;
            }
            .search-btn {
                background: transparent;
                border: none;
            }
            #d_ajax_search_results {
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 9;
                background: $white;
                width: 100%;
                padding: 15px;
                max-height: 60vh;
                overflow-y: auto;
                margin-top: 0;
                border: none;
                -webkit-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
                @media (max-width: $tablet) {
                    width: 100%;
                }
                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div {
                        &:first-child {
                            flex-basis: 20%;
                            img {
                                border: none;
                                background-color: transparent;
                            }
                        }
                        &:nth-child(2) {
                            flex-basis: 60%;
                        }
                        &:nth-child(3) {
                            flex-basis: 20%;
                        }
                    }
                    &.result-link {
                        position: relative;
                        padding: 0;
                        margin: 15px 0;
                        color: $primary_color;
                        font-size: 1.1rem;
                        line-height: initial;
                        font-weight: 400;
                        -webkit-transition: color 0.3s ease;
                        -moz-transition: color 0.3s ease;
                        -o-transition: color 0.3s ease;
                        transition: color 0.3s ease;
                        &:hover {
                            background-color: $light-gray;
                            -webkit-transition: color 0.3s ease;
                            -moz-transition: color 0.3s ease;
                            -o-transition: color 0.3s ease;
                            transition: color 0.3s ease;
                            div {
                                &:first-child {
                                    img {
                                        mix-blend-mode: darken;
                                    }
                                }
                            }
                        }
                    }
                }
                span.where-find {
                    display: none;
                }
                .all_results {
                    display: flex;
                    width: 100%;
                    clear: both;
                    font-size: 1.3rem;
                    line-height: initial;
                    font-weight: 400;
                    color: #fff;
                    text-align: center;
                    background-color: $primary_color;
                    padding: 7px 15px;
                }
            }
        }
        #search,
        .account-menu-btn,
        .wishlist-menu-btn {
            margin: 0 25px 0 0;
            @media (max-width: $tablet) {
                margin: 0 20px 0 0;
            }
        }
        @media (max-width: $tablet) {
            .account-menu-btn {
                display: none;
            }
        }
    }
}

.account-icon {
    display: inline-block;
    width: 20px;
    height: 19px;
    svg {
        display: inline-block;
        width: 20px;
        height: 19px;
    }
}
.wishlist-icon {
    width: 21px;
    height: 19px;
    svg {
        display: inline-block;
        width: 21px;
        height: 19px;
    }
}
#wishlist-total {
    &.active {
        #filling {
            fill: $primary_color !important;
        }
    }
}
.language-switcher {
    .btn-group {
        position: relative;
        vertical-align: unset;
        margin: 0 clamp(30px, 2.083vw, 40px) 0 0;
        @media (max-width: $tablet) {
            margin: 0 0 0 15px;
        }
        button {
            background-color: transparent;
            border: none;
            font-size: 1.3rem;
            text-align: right;
            color: $white;
            span {
                display: inline-block;
            }
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
            :first-child {
                margin-right: 10px;
            }
        }
        .dropdown-menu {
            min-width: 40px;
            left: unset;
            right: 0;
            box-shadow: none;
            border: none;
            border-radius: 0;
            background-color: transparent;
            button {
                color: $white;
            }
        }
        &.open {
            .dropdown-toggle {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }
    }
}

/*cart*/
.right-menu {
    justify-content: flex-end;
    #cart {
        max-width: 25px;
        width: 100%;
        margin: 0;
        text-align: right;
        position: relative;
        .cart-btn {
            display: inline-block;
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
            width: 100%;
            .cart-icon {
                display: inline-block;
                width: 25px;
                height: 25px;
                position: relative;
                background-image: url('../../../icons/cart-icon.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            #cart-total {
                color: $white;
                font-size: 0.9rem;
                font-weight: 500;
                color: $white;
                position: absolute;
                right: -8px;
                top: -5px;
                z-index: 2;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 19px;
                    height: 19px;
                    background-color: $primary_color;
                    border-radius: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 53%;
                    z-index: -1;
                }
            }
        }
    }
}

@media (max-width: $tablet) {
    body:not(.checkout-container) {
        padding-top: calc(var(--header-height) + var(--promo-height));
    }

    #main-header {
        align-items: center;
        position: fixed;
        padding: 15px 25px 5px;
        width: 100%;
        top: var(--top);
        background: #fff;
        z-index: 999;
        min-height: 50px;
        .header-mobile-menu {
            flex-basis: 33.3333333%;
        }
        .main-logo {
            flex-basis: 33.3333333%;
            #logo {
                margin-bottom: 0;
                a {
                    img {
                        max-width: 148px;
                        width: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }
        .alert-dismissable .close,
        .alert-dismissible .close {
            top: -30px;
            right: -7px;
        }
    }

    .right-menu {
        &.mobile {
            flex-basis: 33.3333333%;
            #cart {
                float: right;

                .cart-btn {
                    top: -5px;
                }
            }
            #search {
                margin: 0 auto;
                max-width: unset;
                position: relative;
                .input-group-btn {
                    position: absolute;
                    right: 30px;
                    top: 5px;
                }
                .form-control {
                    border-top: 1px solid #000;
                    padding: 0 35px 0 15px;
                    min-height: 45px;
                }
            }
        }
    }
}

.discount-bar {
    text-align: center;
    padding: 10px 15px;
    background-color: $promo_bar_background_color;
    color: $white;
    font-size: 1.3rem;
    line-height: 1.3;
    .language-switcher {
        display: none;
        position: absolute;
        top: 2px;
        right: 0;
        .btn-group {
            .language-select {
                svg {
                    fill: #ffffff;
                }
            }
        }
    }
    span {
        font-weight: 500;
    }
    @media (max-width: $tablet) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        opacity: 1;
        transition: 0.1s opacity ease 0.5s;
        &.hidden-top {
            top: -100%;
            opacity: 0;
            transition: 0.1s opacity ease;
        }
        .language-switcher {
            display: none;
        }
    }
}

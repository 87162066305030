#blog {
    $side-space: 244px;
    $side-space-rs: 10px;

    .section-wrap {
        @include page-sides-fill($side-space, $side-space-rs, $tablet);

        h1 {
            @extend .header-1;
            text-align: center;
            padding-top: vw(64px);
            padding-bottom: vw(35px);
            margin-bottom: 0;

            @media screen and (max-width:$tablet) {
                padding-top: 30px;
                padding-bottom: 45px;
            }
        }

        p {
            text-align: center;
            margin-bottom: vw(70px);

            @media screen and (max-width:$tablet) {
                margin-bottom: 35px;
            }

            @media screen and (max-width:$tablet) {
                padding-left: 8.5px;
                padding-right: 8.5px;
            }
        }
    }
}
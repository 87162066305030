/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.medium-container {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
}
#information-information .breadcrumb {
    display: none;
}
#information-information.container {
    padding-right: 0;
    padding-left: 0;
}
#information-information .row {
    margin-left: 0;
    margin-right: 0;
}
#information-information #content {
    text-align: justify;
    padding: 0 10px 70px;
}
#information-information h1,
#information-information h1 span {
    font-family: $secondary_font;
    font-size: $header_1_fs;
    text-align: center;
    padding: 35px 0 30px;
    @media (max-width: $tablet) {
        font-size: 2.8rem;
    }
}
#information-information h2,
#information-information h2 span {
    font-size: $header_2_fs;
    @media (max-width: $tablet) {
        font-size: 2.2rem;
    }
}

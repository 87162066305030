/*========================================================*/
/*** SEARCH RESULTS ***/
/*========================================================*/
#result_block p.pull-right.block-text.text-left,
#result_block p.block-text.text-left {
    display: none;
}
.search-container {
    display: block;
    width: 100%;
    padding: 60px var(--side-padding) 7.292vw;
    @media (max-width: $tablet) {
        padding: 30px 10px 50px;
    }
    .category-info {
        text-align: center;
    }
    .category-grid {
        width: 100%;
    }
    .products-grid {
        flex-wrap: wrap;
        .product-col {
            flex-basis: calc(25% - 3px);
            margin: 0 1.5px 5.208vw;
            overflow: hidden;
            @media (max-width: $laptop-xs) {
                flex-basis: calc(33.333% - 3px);
            }
            @media (max-width: $tablet) {
                margin-bottom: 3px;
                background-color: $beige;
            }
            @media (max-width: $tablet-sm) {
                flex-basis: calc(50% - 3px);
            }
            .product-thumb {
                width: unset;
                max-width: unset;
                a {
                    @media (max-width: $tablet) {
                        background-color: $beige;
                    }
                }
                .product-card-arrow {
                    opacity: 0;
                    visibility: hidden;
                    background-color: unset;
                    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                    position: absolute;
                    top: 50%;
                    max-width: 50px;
                    z-index: 10;
                    cursor: pointer;
                    display: block;
                    @media (max-width: 1540px) {
                        max-width: 40px;
                    }
                    @media (max-width: $tablet) {
                        display: none !important;
                        opacity: 1;
                        visibility: visible;
                        max-width: 30px;
                    }
                    &.product-card-arrow-prev {
                        left: 10px;
                        right: auto;
                    }
                    &.product-card-arrow-next {
                        right: 10px;
                        left: auto;
                    }
                }
                &:hover {
                    .product-card-arrow {
                        opacity: 1;
                        visibility: visible;
                        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}
.search-block-wrapper {
    select {
        background-color: $light_beige;
    }
}
.search-container .breadcrumb {
    display: none;
}
.flex-row.search-checkboxes {
    margin-top: 20px;
    input[type='checkbox'] {
        top: 3px;
    }
}
.search-checkbox-item:first-child {
    margin-right: 20px;
}
.search-container input#button-search {
    margin: 35px 0;
}
@media (max-width: $tablet-sm) {
    /*search results*/
    .search-container {
        padding: 50px 15px 0;
    }
    #input-search {
        margin-bottom: 15px;
    }
    .flex-row.search-checkboxes {
        flex-direction: column;
    }
    #button-search {
        max-width: unset;
    }
}

footer {
    margin-top: 40px;
    background-color: $footer_background_color;
    color: $primary_color;
    .header-2 {
        margin: 0 0 30px;
        @media (max-width: $tablet-sm) {
            margin-bottom: 15px;
        }
    }
    .footer-container {
        padding: 85px var(--side-padding-footer) 55px;
        @media (max-width: $tablet) {
            padding-right: 0;
            padding-left: 0;
        }
        @media (max-width: $tablet-sm) {
            padding: 50px 15px 45px;
        }
        @media (max-width: $mobile-sm) {
            padding: 50px 10px 45px;
        }
    }
    .footer-wrapper {
        width: 100%;
        max-width: 1540px;
        margin: 0 auto;
    }
    .footer-menus-wrapper {
        > div {
            flex: 1;
            @media (max-width: $laptop-sm) {
                flex: unset;
                max-width: unset;
                &:not(:last-child) {
                    width: calc(33.3333% - 30px);
                    margin: 0 15px;
                }
            }
            @media (max-width: $tablet-sm) {
                &:not(:last-child) {
                    width: 100%;
                    margin: auto;
                }
                &:last-child {
                    width: 100%;
                    margin: 0 auto;
                }
            }
            .footer-links {
                li {
                    margin-bottom: 10px;
                    a {
                        color: $black;
                        display: inline-block;
                    }
                }
            }
            &:last-child {
                max-width: 175px;
                @media (max-width: $laptop-sm) {
                    margin: 0 15px;
                }
                @media (max-width: $tablet-sm) {
                    max-width: 100%;
                    margin: 0;
                }
            }
        }
        @media (max-width: $tablet-sm) {
            flex-wrap: wrap;
            .footer-title + .footer-links {
                max-height: 0;
                opacity: 0;
                visibility: hidden;
                -webkit-transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
                -moz-transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
                -o-transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
                transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
            }
            .footer-title.open + .footer-links {
                margin-top: 15px;
                margin-bottom: 30px;
                max-height: 500px;
                opacity: 1;
                visibility: visible;
                -webkit-transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
                -moz-transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
                -o-transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
                transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
            }
            .footer-title {
                position: relative;
            }
            &:not(.footer-newsletter) .footer-title {
                padding-bottom: 20px;
                border-bottom: 1px solid $primary_color;
                &::after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 7px;
                    background-image: url('../../../icons/arrow-down.svg');
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: transform 0.3s ease;
                }
                &.open:after {
                    transform: translateY(-50%) rotate(180deg);
                    transition: transform 0.3s ease;
                }
            }
        }
    }
    .footer-newsletter {
        background-color: $light-brown;
        padding: 60px 30px 45px;
        text-align: center;
        @media (max-width: $mobile-sm) {
            padding: 50px 10px 35px;
        }
        .header-1 {
            margin-bottom: 5px;
        }
        .newsletter-promo {
            max-width: 450px;
            margin: 0 auto 20px;
            color: $black;
            @media (max-width: $tablet-sm) {
                margin: 20px auto 30px;
                padding: 0 10px;
            }
        }
        .newsletter-wrapper {
            position: relative;
            border: 1px solid $primary_color;
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }
        .newsletter-form {
            @media (max-width: $tablet-sm) {
                margin: 0 auto 25px;
                text-align: center;
            }
        }
        .newsletter-wrapper input[type='text'] {
            position: relative;
            width: 100%;
            border: none;
            color: $black;
            padding: 8px 60px 8px 20px;
            height: 56px;
            &::placeholder {
                color: $black;
            }
        }
        .newsletter-subscribe-btn {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            padding: 22px 20px;
        }
        .newsletter-submit-arrow svg,
        .newsletter-submit-arrow {
            display: block;
            width: 39px;
            height: 13px;
        }
    }
    .eight8-logo {
        display: inline-block;
        width: 100%;
        max-width: 40px;
        position: relative;
        top: 3px;
    }
    .payment-cards {
        li {
            margin: 0 15px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            @media (max-width: $tablet-sm) {
                margin: 0 10px;
            }
        }
        span {
            display: block;
            width: 100%;
            //opacity: 0.2;
            &.paypal-icon {
                max-width: 50px;
                @media (max-width: $mobile-sm) {
                    max-width: 45px;
                }
            }
            &.visa-icon {
                max-width: 40px;
                @media (max-width: $mobile-sm) {
                    max-width: 30px;
                }
            }
            &.mastercard-icon {
                max-width: 25px;
                @media (max-width: $mobile-sm) {
                    max-width: 17px;
                }
            }
            &.gpay-icon {
                max-width: 40px;
                @media (max-width: $mobile-sm) {
                    max-width: 30px;
                }
            }
            &.applepay-icon {
                max-width: 40px;
                @media (max-width: $mobile-sm) {
                    max-width: 30px;
                }
            }
        }
        svg {
            width: 100%;
        }
    }
    .credits-container {
        background-color: $light-brown;
        padding: 30px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @media (max-width: $tablet) {
            padding: 80px 15px 20px;
        }
        .footer-logo {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50% , -50%);
            -moz-transform: translate(-50% , -50%);
            -o-transform: translate(-50% , -50%);
            transform: translate(-50% , -50%);
            display: inline-block;
            width: 109px;
            height: 109px;
            svg {
                display: inline-block;
                width: 109px;
                height: 109px;
                padding: 15px;
                background-color: $footer-background-color;
                border: 1px solid $footer_background_color;
                border-radius: 50%;
                .st0{
                    fill:$primary_color;
                }
            }
        }
        @media (max-width: $tablet) {
            flex-direction: column;
        }
        @media (max-width: $mobile-sm) {
            .left {
                p {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
#newsletter-subscribe-btn-second{
    background-color: transparent !important;
}

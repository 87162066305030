/*========================================================*/
/*** CONTACT ***/
/*========================================================*/
#information-contact .breadcrumb {
    display: none;
}

#information-contact #content {
    padding-bottom: 70px;
}

#information-contact h1 {
    text-align: center;
    font-size: 2.4rem;
    line-height: initial;
    letter-spacing: .025em;
    font-weight: 600;
    color: #000;
    margin: 0;
    padding: 35px 0 30px;
}

#information-contact h3 {
    font-size: 1.4rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 600;
    color: #000;
    margin: 0;
    text-transform: uppercase;
}

#information-contact form h3 {
    padding-bottom: 20px;
}

ul.contact-info>li,
ul.contact-info>li>a {
    font-size: 1.3rem;
    line-height: 2.4rem;
    letter-spacing: .025em;
    font-weight: 500;
    color: $oil;
}

ul.contact-info>li>a.email-address {
    text-decoration: underline;
}

#information-contact .stores-info {
    padding-top: 50px;
}

#information-contact .addresses {
    padding-bottom: 40px;
}


#contact-us {
    $side-space: 244px;
    $side-space-rs: 10px;
    $spacings: 5px;

    .section-wrap {
        @include page-sides-fill($side-space, $side-space-rs, $tablet);

        h1 {
            @extend .header-1;
            text-align: center;
            padding-top: vw(64px);
            padding-bottom: vw(19px);
        }

        p {
            text-align: center;
            margin-bottom: vw(17px);

            @media screen and (max-width:$tablet) {
                padding-left: 8.5px;
                padding-right: 8.5px;
            }
        }

        .contact-block {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-right: 30px;

            &._top {
                padding-bottom: vw(80px);
                padding-right: 0;

                .contact-image-wrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: vw(120px);

                    img {
                        width: 100%;
                    }
                }
            }

            &._bottom {
                padding-top: vw(60px);
                padding-bottom: vw(60px);
            }


            .addresses {
                .address {
                    border-top: 1px solid #B3B3B3;
                    padding-top: 30px;
                    &:first-child {
                        border-top: none;
                        padding-top: 0;
                    }
                    h2 {
                        font-size: $text_fs;
                        color: $black;
                        font-weight: 500;
                        font-size: 20px;
                    }
                    .contact-info {
                        margin-bottom: 41px;
                        &:last-child {
                            margin-bottom: 25px;
                        }

                        li {
                            display: inline;
                        }
                    }
                    h3 {
                        color: $black;
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }

            form {
                fieldset {
                    display: flex;
                    flex-direction: column;

                    &>span {
                        @extend .header-2;
                        margin-bottom: 50px;
                        text-align: center;
                    }

                    .form-field {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        margin-bottom: 30px;
                        input,
                        textarea {
                            margin-bottom: $spacings;
                        }

                    }

                    .field-group {
                        display: flex;

                        @media screen and (max-width:$mobile) {
                            flex-direction: column;
                        }

                        @media screen and (min-width: ($mobile + 1)) {
                            .form-field {
                                &:first-child {
                                    margin-right: 25px;
                                }

                                &:last-child {
                                    margin-left: calc($spacings / 2);
                                }
                            }
                        }
                    }
                }
            }
            &:last-child {
                padding-right: 0;
                padding-left: 30px;
                flex: 1.9;
            }
            .image-map-container {
                margin-top: vw(80px);
                padding-top: vw(80px);
                border-top: 1px solid #B3B3B3;
                justify-content: space-between;
                .image-container {
                    width: calc(50% - 15px);
                }
                .map-container {
                    width: calc(50% - 15px);
                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .main-contact-block {
            display: flex;

            @media screen and (max-width:$tablet) {
                flex-direction: column;
                max-width: 705px;
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (max-width:$laptop) {
                .contact-block {
                    &:first-child {
                        @media screen and (max-width:$laptop) {
                            min-width: 280px;
                            flex: 1;
                        }

                        @media screen and (max-width:$tablet) {
                            margin-top: 20px;
                            padding-right: 0;
                            border-bottom: 1px solid #B3B3B3;
                            margin-bottom: 30px;
                        }
                    }

                    &:last-child {
                        @media screen and (max-width:$laptop) {
                            flex: 2;
                        }
                        @media screen and (max-width:$tablet) {
                            padding-left: 0;
                        }
                    }

                    .image-map-container {
                        @media screen and (max-width:$mobile) {
                            flex-direction: column;
                            margin-top: 30px;
                            padding-top: 30px;
                        }
                        .image-container {
                            width: calc(50% - 7px);
                            @media screen and (max-width:$mobile) {
                                width: 100%;
                                margin-bottom: 30px;

                            }
                        }
                        .map-container {
                            width: calc(50% - 7px);
                            @media screen and (max-width:$mobile) {
                                width: 100%;
                                height: 450px;
                            }
                        }
                    }
                }
            }
        }
    }
}
#about {
    $side-space: 276px;
    $side-space-sm: 85px;
    $side-space-rs: 10px;

    padding-bottom: vw(110px);

    .section-wrap {
        & > * {
            &:not(.content) {
                @include page-sides-fill($side-space, $side-space-rs, $tablet);
            }
        }

        h1,
        h2 {
            @extend .header-1;
            text-align: center;
        }

        h1 {
            padding-top: vw(68px);
            padding-bottom: vw(68px);

            @media screen and (max-width: $tablet) {
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }

        h2 {
            padding-top: vw(80px);
            padding-bottom: vw(65px);

            @media screen and (max-width: $tablet) {
                padding-top: 72px;
                padding-bottom: 30px;
            }
        }

        @media screen and (max-width: $tablet) {
            #our-philosophy {
                h2 {
                    padding-top: 60px;
                }
            }

            #our-suppliers {
                h2 {
                    padding-top: 90px;
                }
            }
        }

        p {
            text-align: justify;
            margin-bottom: vw(45px);

            @media screen and (max-width: $tablet) {
                margin-bottom: 30px;
            }
        }

        @media screen and (max-width: $tablet) {
            p,
            ul {
                padding-left: 8.5px;
                padding-right: 8.5px;
            }
        }

        .page-anchors {
            text-align: center;
            margin-bottom: vw(94px);

            @media screen and (max-width: $tablet) {
                margin-bottom: 67px;
            }

            a {
                padding-bottom: 5px;
                border-bottom: 1px solid transparent;
                transition: border-color 0.3s ease-in-out;

                &:not(:last-child) {
                    margin-right: vw(35px);
                }

                &:hover {
                    border-color: inherit;
                }
            }

            @media screen and (max-width: $mobile) {
                display: flex;
                flex-direction: column;
                width: 100%;

                a {
                    width: 100%;
                    border-bottom: 1px solid;
                    text-align: left;
                    padding-top: 15px;
                    padding-bottom: 20px;
                    display: flex;
                    justify-content: space-between;

                    // &:after {
                    //     content: url("../../../icons/arrow-down.svg");
                    // }
                }
            }
        }

        .dendron-image-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            .about-sign-container {
                max-width: 906px;
                width: clamp(340px, 47.1875vw, 906px);
                overflow: hidden;
                position: relative;
                .about-sign {
                    width: 100%;
                }
                .about-sign-img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 580px;
                    width: clamp(200px, 30.2083vw, 580px);
                    height: clamp(200px, 30.2083vw, 580px);
                }
            }
        }

        .content-wrap {
            & > div {
                & > img {
                    margin-bottom: vw(80px);

                    @media screen and (max-width: $tablet) {
                        margin-bottom: 30px;
                    }
                }
            }

            & > * {
                &:not(#our-suppliers) {
                    @include page-sides-fill($side-space, $side-space-rs, $tablet);
                }
            }

            #our-suppliers {
                position: relative;

                h2,
                .about-suppliers {
                    @include page-sides-gap($side-space-sm, $side-space-rs, $tablet);
                    overflow: hidden;

                    @media screen and (min-width: 768px) {
                        .swiper-wrapper {
                            display: flex;
                            justify-content: flex-start;
                        }
                    }

                    .supplier {
                        display: flex;
                        flex-direction: column;

                        span {
                            @media screen and (max-width: $tablet) {
                                text-align: center;
                            }

                            &:first-of-type {
                                @extend .header-2;
                                margin-top: vw(20px);
                                margin-bottom: vw(20px);
                                opacity: 0;

                                @media screen and (max-width: $tablet) {
                                    margin-top: 20px;
                                    margin-bottom: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.quote-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: vw(70px);

    .quote {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 590px;

        @media screen and (max-width: $mobile) {
            display: inline;
            width: 85%;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-size: 20px 13px;
            width: 20px;
            height: 13px;

            @media screen and (max-width: $mobile) {
                background-size: 15.62px 10px;
                width: 15.62px;
                height: 10px;
            }
        }

        &:before {
            background-image: url('../../../icons/quote-start.svg');
            top: 0;
            left: -25px;
        }

        &:after {
            background-image: url('../../../icons/quote-end.svg');
            bottom: 0;
            right: -25px;

            @media screen and (max-width: $mobile) {
                right: unset;
                padding-left: 5px;
            }
        }

        span {
            font-family: $primary_font;
            font-size: 2.6rem;
            font-style: italic;
            font-weight: 300;
            text-align: justify;

            @media screen and (min-width: ($mobile + 1)) {
                line-height: 50px;
            }

            @media screen and (max-width: $mobile) {
                font-size: 1.8rem;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}

.floated-children {
    @media screen and (max-width: $tablet) {
        display: flex;
        flex-direction: column;
    }

    &._paragraph {
        @media screen and (min-width: ($tablet + 1px)) {
            p {
                float: left;
                width: 50%;

                &:nth-of-type(odd) {
                    padding-right: vw(75px);
                }

                &:nth-of-type(even) {
                    padding-left: vw(75px);
                }
            }
        }
    }

    &._list {
        ul {
            @media screen and (min-width: ($tablet + 1px)) {
                float: left;
                width: 50%;
                list-style-type: disc;

                &:nth-of-type(odd) {
                    padding-right: vw(75px);
                }

                &:nth-of-type(even) {
                    padding-left: vw(75px);
                }
            }

            &:first-of-type {
                li {
                    &:first-of-type {
                        list-style-type: none;
                        margin-left: 0;
                    }
                }
            }

            &:last-of-type {
                li {
                    &:last-of-type {
                        list-style-type: none;
                        margin-left: 0;

                        @media screen and (max-width: $tablet) {
                            margin-bottom: 40px;
                        }
                    }
                }
            }

            li {
                list-style-type: disc;
                margin-left: 20px;
                margin-bottom: vw(30px);
                text-align: justify;

                @media screen and (max-width: $tablet) {
                    margin-bottom: 30px;
                }
            }
        }
    }

    &:after {
        content: '';
        display: block;
        float: none;
        clear: both;
    }
}

.swiper-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 55%;
    z-index: 2;
    width: 95%;
    pointer-events: none;
    cursor: pointer;

    @media screen and (max-width: $tablet) {
        display: none;
    }

    & > div {
        pointer-events: all;

        img {
            width: vw(63px);
            min-width: 35px;
        }
    }
}

.swiper-pag {
    display: none;

    @media screen and (max-width: $tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        margin-bottom: 85px;
    }

    .swiper-pagination-bullet {
        background-color: transparent;
        width: 16px;
        height: 16px;
        border: 1px solid $light_gray;

        &.swiper-pagination-bullet-active {
            background-color: $oil;
            border-color: transparent;
        }
    }
}
